import React, { useState, useEffect } from "react";
import "../../styles/course.scss";
import { Row, Col, Alert } from "react-bootstrap";
import { request } from "../../services/Request";

function CoursesList() {
  const [courses, setCourses]: any = useState([]);
  const [categories, setCategories]: any = useState([]);
  const [selectedCategory, setSelectedCategory]:any = useState();

  const getCourses = () => {
    request("/api/course", {})
      .then((result: any) => {
        if (result.error) {
        } else {
          setCourses(result.data);
        }
      })
      .catch((error) => {});
  };

  const getCategories = () => {
    request("/api/category/getCount", {})
      .then((result: any) => {
        if (result.error) {
        } else {
          setCategories(result.data);
        }
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getCourses();
    getCategories();
  }, []);

  const searchCategory = (category:string) =>{
    request("/api/course?categories="+category, {})
      .then((result: any) => {
        if (result.error) {
        } else {
          setSelectedCategory(category);
          setCourses(result.data);
        }
      })
      .catch((error) => {});
  }

  return (
    <div className="courses mt-5 mb-5">
      <Row>
        <Col xs={12} md={4} className="mt-5">
          <h6><b>CATEGORÍAS</b></h6>
          <hr/>
          { categories.map((item:any, index:number) => (
            <button className="btn btn-link d-block w-100" onClick={() => searchCategory( item.name ) }>              
              <Row key={index}>
                <Col xs={10} md={11}>
                  <p className="text-left m-0">{ item.name }</p>
                </Col>
                <Col xs={2} md={1}>
                  <p className="m-0">{ item.total }</p>
                </Col>
              </Row>
            </button>
          )) }
        </Col>
        <Col xs={12} md={{ span: 8}} className="mt-5">
          {
            selectedCategory &&
            <div>              
              <h6><b>CATEGORÍA: {selectedCategory}</b></h6>
              <hr/>
            </div>
          }
          {courses.length > 0 ? (
            <div className="course-list">
              {courses.map((item: any, index: number) => (
                <div className="course-item" key={index}>
                  <Row className="justify-content-center">
                    <Col xs={12} md={2}>
                      <a
                        href={`/course/${item._id}`}
                      >
                        <img
                          src={`/videos/image_${item._id}.jpg`}
                          className="w-100"
                        />
                      </a>
                    </Col>
                    <Col xs={12} md={8}>
                      <p className="course-title">
                        <b>{item.title}</b>
                      </p>
                    </Col>
                    <Col xs={12} md={2}>
                      <a
                        className="btn btn-primary"
                        href={`/course/${item._id}`}
                      >
                        Ver seminario
                      </a>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          ) : (
            <Alert variant="warning">No hay cursos registrados.</Alert>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default CoursesList;
