import React, { useState, useEffect } from "react";
import { Table, Container, Button, Modal, Toast } from "react-bootstrap";
import { request, checkPermissions } from "../../../services/Request";
import { format } from "date-fns";
import { Trash, Pencil, Lock, Unlock } from "react-bootstrap-icons";
import { useParams } from "react-router";

function ManageListUsers() {
  const [users, setUsers]: any = useState([]);
  const [showModal, setModal] = useState(false);
  const [showToggleModal, setToggleModal] = useState(false);
  const [showAlert, setAlert] = useState(false);
  const [selected, setSelected]:any = useState({});
  const [alertContent, setContent]:any = useState({
    title:'',
    body:''
  });

  const getUsers = () => {
    checkPermissions("users")
    .then(()=>{      
      request("/api/user?role=1", {})
        .then((result: any) => {
          if (result.error) {
          } else {
            setUsers(result.data);
          }
        })
        .catch((error) => {});
    })
  };


  useEffect(() => {
    getUsers();
  }, []);

  const remove = (item:any) => {
    setSelected(item);
    setModal(true);
  }

  const toggle = (item:any) => {
    setSelected(item);
    setToggleModal(true);
  }

  const handleClose = () => {
    setModal(false);
  }


  const confirmRemove = () => {
    request("/api/user/"+selected._id, {
      method:'DELETE'
    })
      .then((result: any) => {
        if (result.error) {
          setModal(false);
          setContent({
            title: 'Eliminar usuario',
            body: 'Ocurrió un error al realizar el proceso. Favor de intentarlo más tarde'
          });
          setAlert(true);
        } else {
          getUsers();
          setModal(false);
        }
      })
      .catch((error) => {
        setModal(false);
        setContent({
          title: 'Eliminar usuario',
          body: 'Ocurrió un error al realizar el proceso. Favor de intentarlo más tarde'
        });
        setAlert(true);
      });
  }

  const confirmToggle = () => {
    request("/api/user/toggle/"+selected._id, {
      method:'PUT',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({status: !selected.status}),
    })
      .then((result: any) => {
        if (result.error) {
          setToggleModal(false);
          setContent({
            title: selected.status ? 'Deshabilitar usuario' :'Habilitar usuario',
            body: 'Ocurrió un error al realizar el proceso. Favor de intentarlo más tarde'
          });
          setAlert(true);
        } else {
          getUsers();
          setToggleModal(false);
        }
      })
      .catch((error) => {
        setToggleModal(false);
        setContent({
          title: selected.status ? 'Deshabilitar usuario' :'Habilitar usuario',
          body: 'Ocurrió un error al realizar el proceso. Favor de intentarlo más tarde'
        });
        setAlert(true);
      });
  }

  return (
    <div className="manage list-users">
      <Container>
        <h1>Listado de usuarios</h1>
        <a
          href={`/manage/users/new`}
          className="btn btn-primary"
        >
          Nuevo usuario
        </a>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Correo</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            {users.map((item: any, index: number) => (
              <tr key={index}>
                <td>{item.name +" "+ item.lastname}</td>
                <td>{item.email}</td>
                <td>
                  <a href={"/manage/users/"+item._id+"/edit"} title="Editar" className="btn btn-info">
                    <Pencil/>
                  </a>
                  {
                    item.status == true &&
                    <Button variant="danger" onClick={()=>toggle(item)} title="Deshabilitar usuario">
                      <Lock/>
                    </Button>
                  }
                  {
                    item.status == false &&
                    <Button variant="danger" onClick={()=>toggle(item)} title="Habilitar usuario">
                      <Unlock/>
                    </Button>
                  }
                  <Button variant="danger" onClick={()=>remove(item)} title="Eliminar">
                    <Trash/>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de eliminar el usuario "{selected.name}"?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={confirmRemove}>
            Sí
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showToggleModal} onHide={() => setToggleModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{ selected.status ? "Deshabilitar" : "Habilitar" } usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de { selected.status ? "deshabilitar" : "habilitar" } el usuario "{selected.name}"?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={confirmToggle}>
            Sí
          </Button>
        </Modal.Footer>
      </Modal>
      <Toast
        onClose={() => setAlert(false)}
        show={showAlert}
        className={showAlert ? "d-block" : "d-none"}
      >
        <Toast.Header>
          <strong className="mr-auto">{ alertContent.title }</strong>
        </Toast.Header>
        <Toast.Body>{ alertContent.body }</Toast.Body>
      </Toast>
    </div>
  );
}

export default ManageListUsers;
