import React from "react";
import { Row, Col } from "react-bootstrap";
import "../styles/footer.scss";

function Footer() {
  return (
    <header className="footer">
      <Row>
        <Col>
          <div className="d-flex align-items-center">
            <p className="m-0">Copyright 2020.</p>
            <a className="m-0 ml-3 text-white" href="https://americansoftwoodslatam.com/" target="_blank">Regresar a la página</a>
          </div>
        </Col>
      </Row>
    </header>
  );
}

export default Footer;
