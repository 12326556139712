import React, { useState, useEffect } from "react";
import { Table, Container, Button, Modal, Alert, Toast, Row, Col } from "react-bootstrap";
import { Trash, Pencil, List } from "react-bootstrap-icons";
import { request } from "../../../services/Request";
import { format } from "date-fns";
import Loader from "react-loader-spinner";

function ManageListCourses() {
  const [courses, setCourses]: any = useState([]);
  const [showModal, setModal] = useState(false);
  const [showAlert, setAlert] = useState(false);
  const [selected, setSelected]:any = useState({});
  const [loading, setLoading]:any = useState(false);
  const [alertContent, setContent]:any = useState({
    title:'',
    body:''
  });

  const getCourses = () => {
    setLoading(true);
    request("/api/course?published=all", {})
      .then((result: any) => {
        if (result.error) {
          setLoading(false);
        } else {
          setCourses(result.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCourses();
  }, []);

  const remove = (item:any) => {
    setSelected(item);
    setModal(true);
  }

  const handleClose = () => {
    setModal(false);
  }

  const confirmRemove = () => {
    request("/api/course/"+selected._id, {
      method:'DELETE'
    })
      .then((result: any) => {
        if (result.error) {
          setModal(false);
          setContent({
            title: 'Eliminar curso',
            body: 'Ocurrió un error al realizar el proceso. Favor de intentarlo más tarde'
          });
          setAlert(true);
        } else {
          getCourses();
          setModal(false);
        }
      })
      .catch((error) => {
        setModal(false);
        setContent({
          title: 'Eliminar curso',
          body: 'Ocurrió un error al realizar el proceso. Favor de intentarlo más tarde'
        });
        setAlert(true);
      });
  }

  return (
    <div className="manage list-courses">
      {loading ? (
          <Row className="pt-5 pb-5 justify-content-center w-100">
            <Col xs={12} md={4}>
              <Loader
                type="Oval"
                color="#439a86"
                visible={true}
                width={300}
                height={300}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12} md={{span:10,offset:1}}>
              <h1>Cursos</h1>
              <a href="/manage/courses/new" className="btn btn-primary mt-5 mb-1">
                Nuevo curso
              </a>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Título</th>
                    <th className="w-50">Descripción</th>
                    <th>Fecha</th>
                    <th>Publicado</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  {courses.map((item: any, index: number) => (
                    <tr key={index}>
                      <td>{item.title}</td>
                      <td>
                        <a href={`/manage/courses/` + item._id}>{item.description}</a>
                      </td>
                      <td>{format(new Date(item.created_at), "dd/MM/yyyy")}</td>
                      <td>{ item.published ? 'Sí' : 'No' }</td>
                      <td>
                        <a href={"/manage/courses/"+item._id+"/edit"} title="Editar" className="btn btn-info">
                          <Pencil/>
                        </a>
                        <a href={"/manage/courses/"+item._id} title="Ver temas" className="btn btn-info">
                          <List/>
                        </a>
                        <Button variant="danger" onClick={()=>remove(item)} title="Eliminar">
                          <Trash/>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )
      }
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar curso</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de eliminar el curso "{selected.title}"?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={confirmRemove}>
            Sí
          </Button>
        </Modal.Footer>
      </Modal>
      <Toast
        onClose={() => setAlert(false)}
        show={showAlert}
        className={showAlert ? "d-block" : "d-none"}
      >
        <Toast.Header>
          <strong className="mr-auto">{ alertContent.title }</strong>
        </Toast.Header>
        <Toast.Body>{ alertContent.body }</Toast.Body>
      </Toast>
    </div>
  );
}

export default ManageListCourses;
