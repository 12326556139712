import React, { useRef, useState } from "react";
import { Row, Col, Form, Button, Container, Toast } from "react-bootstrap";
import { request } from "../../../services/Request";
import { useParams } from "react-router";
import Loader from "react-loader-spinner";
import { Trash } from "react-bootstrap-icons";

function ManageNewTopic() {
  const [selectedFiles, setFiles]:any = useState([]);
  const [topic, setTopic]: any = useState({
    videos: []
  });
  const { id } = useParams();
  const [loading, setLoading]: any = useState(false);
  const [alertContent, setContent]: any = useState({
    title: "",
    body: "",
  });
  const [showAlert, setAlert] = useState(false);

  const onSubmitHandler = (event: any) => {
    console.log(topic);
    setLoading(true);
    event.preventDefault();
    let data = new FormData();
    for (var x = 0; x < selectedFiles.length ; x++) {
      data.append("file[]", selectedFiles[x]);
    }
    data.append("course", id);
    for (const field in topic) {
      if(field == 'videos'){
        data.append("videos", JSON.stringify( topic[field] ) );
      }else{
        data.append(field, topic[field]);
      }
    }
    request("/api/topic", {
      method: "POST",
      body: data,
    })
      .then((result: any) => {
        if(result.error){
          setLoading(false);
          setContent({
            title: "Error",
            body: "Ocurrió un error. Favor de intentarlo más tarde.",
          });
          setAlert(true);
        }else{          
          window.location.replace("/manage/courses/" + id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangeHandler = (event: any) => {
    if (event.target.name === "video") {
      let files = selectedFiles;
      files.push(event.target.files[0]);
      setFiles(files);
    } else {
      let data = topic;
      data[event.target.name] = event.target.value;
      setTopic(data);
    }
  };

  const addVideo = () => {
    console.log("Agregar video");
    let data = topic;
    if(!data.videos){
      data.videos = [];
    }
    data.videos.push({
      name: '',
      lang: ''
    });
    console.log(data);
    setTopic({...data});    
  }

  const updateVideo = (event:any, index:number) => {
    let data = topic;
    if (event.target.name === "video") {
      data.videos[index]["name"] = event.target.files[0].name;
      onChangeHandler(event);
    }else{
      data.videos[index][ event.target.name ] = event.target.value;
    }
    setTopic(data);
  }

  const remove = (event:any, index:number) => {
    let data = topic;
    data.videos = data.videos.filter( (item:any, indexArray:number) => {
      return index != indexArray;
    });
    setTopic({...data});
    let videos = selectedFiles;
    videos.splice(index,1);
    setFiles(videos);
  }

  return (
    <div className="manage new-topic">
      <div className={"loader-container " + (loading ? "d-flex" : "d-none")}>
        <Row className="pt-5 pb-5 justify-content-center w-100">
          <Col xs={12} md={4}>
            <Loader
              type="Oval"
              color="#439a86"
              visible={true}
              width={300}
              height={300}
            />
          </Col>
        </Row>
      </div>
      <Container>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group as={Row} controlId="formHorizontalTitle">
            <Form.Label column sm={2}>
              Tema
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="title"
                placeholder="Título"
                onChange={onChangeHandler}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formHorizontalDescription">
            <Form.Label column sm={2}>
              Descripción
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                name="description"
                as="textarea"
                rows="3"
                onChange={onChangeHandler}
              />
            </Col>
          </Form.Group>
          <br/>
          <Form.Group as={Row} controlId="formHorizontalDescription">
            <Form.Label column sm={2}>
            </Form.Label>
            <Col sm={10}>
              <a className="btn btn-secondary text-white" onClick={addVideo}> Agregar video</a> 
            </Col>
          </Form.Group>
          <br/>
          {
            topic.videos.map((item:any, index:number)=>(
               <div>
                 <hr/>
                <Row>
                  <Col sm={{offset:2}}>
                    <Form.Group as={Row} controlId="formHorizontalImage">
                      <Form.Label column sm={4}>
                        Video { index +1 }
                      </Form.Label>
                      <Col sm={{offset:1}}>
                        <Form.File
                          id="video"
                          name="video"
                          label="MP4"
                          onChange={(event:any) => updateVideo(event, index)}
                        />
                      </Col>
                    </Form.Group>                  
                  </Col>
                  <Col>
                    <Form.Group as={Row} controlId={"lang-"+index}>
                      <Form.Label column sm={2}>
                        Idioma
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          name="lang"
                          onChange={ (event:any) => updateVideo(event, index) }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    { index == (topic.videos.length - 1) &&                     
                      <a onClick={ (event:any) => remove(event, index)} className="btn btn-danger">
                        <Trash/>
                      </a>
                    }
                  </Col>
                </Row>
               </div> 
            ))
          }         
          <br/><br/>
          <Form.Group as={Row}>
            <Col sm={{ span: 10, offset: 2 }}>
              <Button type="submit">Guardar</Button>
            </Col>
          </Form.Group>
        </Form>
      </Container>
      <Toast
        onClose={() => setAlert(false)}
        show={showAlert}
        className={showAlert ? "d-block" : "d-none"}
      >
        <Toast.Header>
          <strong className="mr-auto">{alertContent.title}</strong>
        </Toast.Header>
        <Toast.Body>{alertContent.body}</Toast.Body>
      </Toast>
    </div>
  );
}

export default ManageNewTopic;
