import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Form, Button, Toast } from "react-bootstrap";
import { request } from "../../../services/Request";
import Loader from "react-loader-spinner";
import Select from "react-select";

function ManageNewCourse() {
  const selectedFile = useRef("");
  const [course, setCourse]: any = useState({});
  const [authors, setAuthors]: any = useState([]);
  const [categories, setCategories ]:any = useState([]);
  const [selectedAuthors, setSelectedAuthor]: any = useState([]);
  const [mainAuthor, setMainAuthor]: any = useState({ label: "", value: "" });
  const [showAlert, setAlert] = useState(false);
  const [loading, setLoading]: any = useState(false);
  const [alertContent, setContent]: any = useState({
    title: "",
    body: "",
  });
  const [filename, setFile] = useState("");

  const onSubmitHandler = (event: any) => {
    setLoading(true);
    event.preventDefault();
    let data = new FormData();
    data.append("file", selectedFile.current);
    data.append(
      "authors",
      selectedAuthors.map((author: any) => {
        return author.value;
      })
    );
    data.append("main_author", mainAuthor.value);
    for (const field in course) {
      if (field != "_id") {
        data.append(field, course[field]);
      }
    }
    request("/api/course", {
      method: "POST",
      body: data,
    })
      .then((result: any) => {
        if (result.error) {
          setContent({
            title: "Error",
            body: "Ocurrió un error. Favor de intentarlo más tarde.",
          });
          setAlert(true);
          setLoading(false);
        } else {
          window.location.replace("/manage/courses");
        }
      })
      .catch((error) => {
        console.log(error);
        setContent({
          title: "Error",
          body: "Ocurrió un error. Favor de intentarlo más tarde.",
        });
        setAlert(true);
        setLoading(false);
      });
  };
  const onChangeHandler = (event: any) => {
    if (event.target.name === "imagen") {
      selectedFile.current = event.target.files[0];
      setFile((event.target.files[0] as HTMLInputElement).name);
    } else if(event.target.name == "issue_certificate" 
      || event.target.name == "issue_survey"
      || event.target.name == "published"){
      let data = course;
      if( data[event.target.name] ){
        delete data[event.target.name];
      }else{
        data[event.target.name] = event.target.value;
      }
      setCourse(data);
    }else {
      let data = course;
      data[event.target.name] = event.target.value;
      setCourse(data);
    }
  };

  const upload = () => {
    (document.getElementById("imagen") as HTMLInputElement).click();
  };

  const getCategories = () => {
    request("/api/category",{})
    .then((result:any)=>{
      if(result.data){
        setCategories(
          result.data.map((category: any) => {
            return { value: category.name, label: category.name };
          })
        );
      }
    }).catch((error:any)=>{

    });
  }

  useEffect(() => {
    setLoading(true);
    getAuthors();
    getCategories();
  }, []);

  const getAuthors = () => {
    request("/api/author", {})
      .then((result: any) => {
        if (result.data) {
          setAuthors(
            result.data.map((author: any) => {
              return { value: author._id, label: author.name };
            })
          );
          setLoading(false);
        }
      })
      .catch((error: any) => {});
  };

  const onChangeHandlerAuthors = (data: any) => {
    setSelectedAuthor(data ? data : []);
  };

  const onChangeHandlerCategories = (data: any) => {
    if(data){      
      let courseData = course;
      courseData["categories"] = data.map((item:any) => {
        return item.value;
      });
      //console.log(data, courseData["categories"]);
      setCourse(courseData);
    }
  };

  const onChangeHandlerMainAuthor = (data: any) => {
    setMainAuthor(data);
  };

  return (
    <div className="manage new-course">
      <div className={"loader-container " + (loading ? "d-flex" : "d-none")}>
        <Row className="pt-5 pb-5 justify-content-center w-100">
          <Col xs={12} md={4}>
            <Loader
              type="Oval"
              color="#439a86"
              visible={true}
              width={300}
              height={300}
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <h2>Nuevo curso</h2>
          <Form onSubmit={onSubmitHandler}>
            <Form.Group as={Row} controlId="formHorizontalTitle">
              <Form.Label column sm={2}>
                Título
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Título"
                  onChange={onChangeHandler}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalDescription">
              <Form.Label column sm={2}>
                Descripción Corta
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="description"
                  as="textarea"
                  rows="3"
                  onChange={onChangeHandler}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalDescriptionLong">
              <Form.Label column sm={2}>
                Descripción Larga
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="long_description"
                  as="textarea"
                  rows="3"
                  onChange={onChangeHandler}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalAuthors">
              <Form.Label column sm={2}>
                Autor
              </Form.Label>
              <Col sm={10}>
                <Select
                  options={authors}
                  isMulti
                  name="authors"
                  value={selectedAuthors}
                  onChange={onChangeHandlerAuthors}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalMainAuthor">
              <Form.Label column sm={2}>
                Categorías
              </Form.Label>
              <Col sm={10}>
                <Select
                  options={categories}
                  name="categories"
                  isMulti
                  onChange={onChangeHandlerCategories}
                />
              </Col>
            </Form.Group>
            {selectedAuthors.length > 1 && (
              <Form.Group as={Row} controlId="formHorizontalMainAuthor">
                <Form.Label column sm={2}>
                  Autor Principal
                </Form.Label>
                <Col sm={10}>
                  <Select
                    options={selectedAuthors}
                    name="main_author"
                    value={mainAuthor}
                    onChange={onChangeHandlerMainAuthor}
                  />
                </Col>
              </Form.Group>
            )}
            <br/>
            <Form.Check 
            className="ml-3"
              type={"checkbox"}
              id={`issue_certificate`}
              name="issue_certificate"
              label={`Expedir certificado`}
              value="true"
              onChange={onChangeHandler}
            />
            <br/>
            <Form.Check 
            className="ml-3"
              type={"checkbox"}
              id={`issue_survey`}
              name="issue_survey"
              label={`Enviar encuesta`}
              value="true"
              onChange={onChangeHandler}
            />
            <br />
            <Form.Group as={Row} controlId="formHorizontalImage">
              <Form.Label column sm={2}>
                Imagen
              </Form.Label>
              <Col sm={10}>
                <p>
                  Subir una imagen descriptiva. Tamaño recomendado de 600x300
                  pixeles.
                </p>
                <div className="d-flex align-items-center">
                  <a className="text-white btn btn-info" onClick={upload}>
                    Subir archivo
                  </a>
                  <p className="m-0 ml-3">{filename}</p>
                </div>
                <Form.File
                  id="imagen"
                  name="imagen"
                  label="Imagen descriptiva - 600x300"
                  onChange={onChangeHandler}
                  style={{ visibility: "hidden" }}
                />
              </Col>
            </Form.Group>
            <br/>         
            <Form.Check 
            className="ml-3"
              type={"checkbox"}
              id={`published`}
              name="published"
              label={`¿Publicar curso?`}
              value="true"
              onChange={onChangeHandler}
            />
            <br/>   
            <Form.Group as={Row}>
              <Col sm={{ span: 10, offset: 2 }}>
                <Button type="submit" className="">
                  Guardar
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Toast
        onClose={() => setAlert(false)}
        show={showAlert}
        className={showAlert ? "d-block" : "d-none"}
      >
        <Toast.Header>
          <strong className="mr-auto">{alertContent.title}</strong>
        </Toast.Header>
        <Toast.Body>{alertContent.body}</Toast.Body>
      </Toast>
    </div>
  );
}

export default ManageNewCourse;
