import React, { useState, useEffect } from "react";
import { Table, Container, Button, Modal, Toast } from "react-bootstrap";
import { request } from "../../../services/Request";
import { format } from "date-fns";
import { Trash, Pencil } from "react-bootstrap-icons";
import { useParams } from "react-router";

function ManageListTopics() {
  const [topics, setTopics]: any = useState([]);
  const [course, setCourse]: any = useState({ title: "" });
  const { id } = useParams();
  const [showModal, setModal] = useState(false);
  const [showAlert, setAlert] = useState(false);
  const [selected, setSelected]:any = useState({});
  const [alertContent, setContent]:any = useState({
    title:'',
    body:''
  });

  const getTopics = () => {
    request("/api/topic?course=" + id, {})
      .then((result: any) => {
        if (result.error) {
        } else {
          setTopics(result.data);
        }
      })
      .catch((error) => {});
  };

  const getCourse = () => {
    request("/api/course/" + id, {})
      .then((result: any) => {
        if (result.error) {
        } else {
          setCourse(result.data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getTopics();
    getCourse();
  }, [id]);

  const remove = (item:any) => {
    setSelected(item);
    setModal(true);
  }

  const handleClose = () => {
    setModal(false);
  }

  const confirmRemove = () => {
    request("/api/topic/"+selected._id, {
      method:'DELETE'
    })
      .then((result: any) => {
        if (result.error) {
          setModal(false);
          setContent({
            title: 'Eliminar tema',
            body: 'Ocurrió un error al realizar el proceso. Favor de intentarlo más tarde'
          });
          setAlert(true);
        } else {
          getTopics();
          setModal(false);
        }
      })
      .catch((error) => {
        setModal(false);
        setContent({
          title: 'Eliminar tema',
          body: 'Ocurrió un error al realizar el proceso. Favor de intentarlo más tarde'
        });
        setAlert(true);
      });
  }

  return (
    <div className="manage list-courses">
      <Container>
        <h1>Curso</h1>
        <h4>{course.title}</h4>
        <a
          href={`/manage/courses/` + id + `/newtopic`}
          className="btn btn-primary"
        >
          Nuevo Tema
        </a>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Título</th>
              <th>Descripción</th>
              <th>Fecha</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            {topics.map((item: any, index: number) => (
              <tr key={index}>
                <td>{item.title}</td>
                <td>{item.description}</td>
                <td>{format(new Date(item.created_at), "dd/MM/yyyy")}</td>
                <td>
                  <a href={"/manage/courses/"+id+"/edit/"+item._id} title="Editar" className="btn btn-info">
                    <Pencil/>
                  </a>
                  <Button variant="danger" onClick={()=>remove(item)} title="Eliminar">
                    <Trash/>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar tema</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de eliminar el tema "{selected.title}"?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={confirmRemove}>
            Sí
          </Button>
        </Modal.Footer>
      </Modal>
      <Toast
        onClose={() => setAlert(false)}
        show={showAlert}
        className={showAlert ? "d-block" : "d-none"}
      >
        <Toast.Header>
          <strong className="mr-auto">{ alertContent.title }</strong>
        </Toast.Header>
        <Toast.Body>{ alertContent.body }</Toast.Body>
      </Toast>
    </div>
  );
}

export default ManageListTopics;
