import React, { useState, useEffect } from "react";
import "../../styles/user.scss";
import { Row, Col } from "react-bootstrap";
import CoursesList from "../course/CoursesList";

function UserDashboard() {
  return (
    <div className="dashboard">
      <Row>
        <Col>
          <CoursesList />
        </Col>
      </Row>
    </div>
  );
}

export default UserDashboard;
