import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { request } from "../../services/Request";

function ConfirmUser() {
  const { token } = useParams();
  const [userData, setData]: any = useState({});
  const [error, setErrorData] = useState(false);

  useEffect(() => {
    request("/api/auth/getUser", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: token }),
    }).then((result: any) => {
      if (result.error) {
        window.location.href = "/login";
      } else {
        request("/api/auth/confirmUser", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: result.data._id }),
        }).then((result: any) => {
          if (result.error) {
            window.location.href = "/login";
          } else {
            window.location.href = "/login";
          }
        });
      }
    });
  }, [setData, token]);

  return (
    <div className="set-password p-5">

    </div>
  );
}

export default ConfirmUser;
