import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.scss";

import Header from "./partials/Header";
import Footer from "./partials/Footer";
import Login from "./pages/auth/Login";
import ConfirmUser from "./pages/auth/ConfirmUser";
import SetPassword from "./pages/auth/SetPassword";
import UserDashboard from "./pages/user/UserDashboard";
import Course from "./pages/course/Course";
import ManageNewCourse from "./pages/manage/course/ManageNewCourse";
import ManageEditCourse from "./pages/manage/course/ManageEditCourse";
import ManageListCourses from "./pages/manage/course/ManageListCourses";
import ManageListCustomers from "./pages/manage/customer/ManageListCustomers";
import ManageNewAuthor from "./pages/manage/author/ManageNewAuthor";
import ManageEditAuthor from "./pages/manage/author/ManageEditAuthor";
import ManageListAuthors from "./pages/manage/author/ManageListAuthors";
import ManageListTopics from "./pages/manage/topic/ManageListTopics";
import ManageNewTopic from "./pages/manage/topic/ManageNewTopic";
import ManageEditTopic from "./pages/manage/topic/ManageEditTopic";
import ManageEditCategory from "./pages/manage/category/ManageEditCategory";
import ManageNewCategory from "./pages/manage/category/ManageNewCategory";
import ManageListCategories from "./pages/manage/category/ManageListCategories";
import ManageEditUser from "./pages/manage/user/ManageEditUser";
import ManageNewUser from "./pages/manage/user/ManageNewUser";
import ManageListUsers from "./pages/manage/user/ManageListUsers";
import ReportList from "./pages/report/ReportList";
import ReportView from "./pages/report/ReportView";
import ReportGeneral from "./pages/report/ReportGeneral";
import Report1 from "./pages/report/Report1";

import ProtectedRoute from "./services/ProtectedRoute";

function App() {
  return (
    <Router>
      <Header />
      <div className="App">
        <Switch>
          <Route exact path="/">
            <UserDashboard />
          </Route>
          <Route exact path="/dashboard/all-courses">
            <UserDashboard />
          </Route>
          <Route exact path="/course/:id">
            <Course />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/nuevacontrasena/:token">
            <SetPassword />
          </Route>
          <Route exact path="/newpassword/:token">
            <SetPassword />
          </Route>
          <Route exact path="/confirm/:token">
            <ConfirmUser />
          </Route>
          <ProtectedRoute
            path="/manage/courses/new"
            component={ManageNewCourse}
            setRole={1}
          />
          <ProtectedRoute
            path="/manage/courses/:id/edit"
            component={ManageEditCourse}
            setRole={1}
            exact
          />
          <ProtectedRoute
            path="/manage/authors/new"
            component={ManageNewAuthor}
            setRole={1}
          />
          <ProtectedRoute
            path="/manage/authors/:id/edit"
            component={ManageEditAuthor}
            setRole={1}
            exact
          />
          <ProtectedRoute
            path="/manage/authors"
            component={ManageListAuthors}
            setRole={1}
          />
          <ProtectedRoute
            path="/manage/categories/new"
            component={ManageNewCategory}
            setRole={1}
          />
          <ProtectedRoute
            path="/manage/categories/:id/edit"
            component={ManageEditCategory}
            setRole={1}
            exact
          />
          <ProtectedRoute
            path="/manage/users/new"
            component={ManageNewUser}
            setRole={1}
          />
          <ProtectedRoute
            path="/manage/users/:id/edit"
            component={ManageEditUser}
            setRole={1}
            exact
          />
          <ProtectedRoute
            path="/manage/users"
            component={ManageListUsers}
            setRole={1}
          />
          <ProtectedRoute
            path="/manage/customers"
            component={ManageListCustomers}
            setRole={1}
          />
          <ProtectedRoute
            path="/manage/categories"
            component={ManageListCategories}
            setRole={1}
          />
          <ProtectedRoute
            path="/manage/courses/:id/newtopic"
            component={ManageNewTopic}
            setRole={1}
          />
          <ProtectedRoute
            path="/manage/courses/:id/edit/:topicId"
            component={ManageEditTopic}
            setRole={1}
          />
          <ProtectedRoute
            path="/manage/courses/:id"
            component={ManageListTopics}
            setRole={1}
          />
          <ProtectedRoute
            path="/manage/courses"
            component={ManageListCourses}
            setRole={1}
          />
          <ProtectedRoute
            path="/report/list"
            component={ReportList}
            setRole={1}
          />
          <ProtectedRoute
            path="/report/general"
            component={ReportGeneral}
            setRole={1}
          />
          <ProtectedRoute
            path="/report/report1/:id/:date_from/:date_to"
            component={Report1}
            setRole={1}
          />
          <ProtectedRoute
            path="/report/view/:id"
            component={ReportView}
            setRole={1}
          />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
