import React, { useState, useEffect } from "react";
import "../../styles/report.scss";
import { Row, Col, Card, Button, Nav, Table, Form } from "react-bootstrap";
import { request } from "../../services/Request";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useParams } from "react-router";

function Report1() {
  const [surveys, setSurveys]: any = useState([]);
  const { id, date_from, date_to } = useParams();
  const sections = [
    "0) APA",
    "1) APA",
    "2) APA",
    "3) APA",
    "4) APA",
    "5) APA",
    "6) APA",
    "7) APA",
    "8) APA",
    "9) APA",
    "10) APA",
  ];

  useEffect(() => {
    searchResults();
  }, [id]);

  const searchResults = () => {
    request(
      `/api/survey/search?date_from=${date_from}&date_to=${date_to}&course=${id}`,
      {}
    )
      .then((result: any) => {
        if (result.data) {
          setSurveys(result.data);
        }
      })
      .catch((error: any) => {});
  };

  return (
    <div className="report">
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <div className="mt-3 report-table">
            <Table striped bordered hover responsive>
              <tbody>
                {sections.map((section: any, skey: number) => (
                  <tr key={skey}>
                    <td>{section}</td>
                    {surveys.map((survey: any, sukey: number) => (
                      <td key={sukey}>{sukey + 1}</td>
                    ))}
                    <td>Total</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Report1;
