import React, { useState, useEffect } from "react";
import "../../styles/report.scss";
import { Row, Col, Card, Button, Nav, Table } from "react-bootstrap";
import { request } from "../../services/Request";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { Eye } from "react-bootstrap-icons";

function ReportList() {
  const [surveys, setSurveys]: any = useState([]);

  useEffect(() => {
    request("/api/survey", {})
      .then((result: any) => {
        if (result.data) {
          setSurveys(result.data);
        }
      })
      .catch((error: any) => {});
  }, []);

  return (
    <div className="report">
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <Row>
            <Col>
              <h2>Reporte de respuestas</h2>
            </Col>
            <Col className="text-right">
              <a className="btn btn-primary" href="/api/survey/report_statistics" target="_blank">
                Descargar estadísticas
              </a>
              <a className="btn btn-primary" href="/report/general">
                Ver reporte por curso
              </a>
            </Col>
          </Row>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Usuario</th>
                <th>Curso</th>
                <th>Fecha</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {surveys.map((item: any, i: number) => (
                <tr key={i}>
                  <td>
                    {item.user ? item.user.name + " " + item.user.lastname : ""}
                  </td>
                  <td>{item.course.title}</td>
                  <td>
                    {format(new Date(item.created_at), "d/MMMM/yyyy hh:mm", {
                      locale: es,
                    })}
                  </td>
                  <td>
                    <a
                      className="btn btn-info"
                      href={"/report/view/" + item._id}
                    >
                      <Eye />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

export default ReportList;
