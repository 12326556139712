import React, { useState, useEffect } from "react";
import { Row, Form, Col, Alert, Button, Toast } from "react-bootstrap";
import { request } from "../../services/Request";
import "../../styles/survey.scss";
import { useParams } from "react-router";

function ReportView() {
  const [data, setData]: any = useState({});
  const [authors, setAuthors]:any = useState([]);
  const [course, setCourse]:any = useState({});
  const {id} = useParams();

  useEffect(()=>{
    request("/api/survey/"+id,{})
    .then((result:any)=>{
      if(result.data){
        setData(result.data);
        request("/api/course/"+result.data.course,{})
        .then((resultCourse:any)=>{
          if(resultCourse.data){
            setCourse(resultCourse.data);
            setAuthors(resultCourse.data.authors);
          }
        })
        .catch((error:any)=>{

        })
      }
    })
    .catch((error:any)=>{

    })
  },[]);

  const q3: any = [
    "Arquitecto",
    "Distribuidor de materiales para construcción",
    "Paisajista /Proyectista",
    "Ingeniero",
    "Fabricante de Muebles",
    "Gobierno",
    "Profesor",
    "Estudiante",
    "Diseñador de interiores",
    "Distribuidor de Paneles/Madera",
    "Desarrollador",
    "Constructor",
    "Medios",
    "Asociación",
  ];
  const q4 = [
    "Pino Amarillo del Sur",
    "OSB",
    "Glulam /Vigas Laminadas",
    "HDO / MDO / PSF",
    "Madera Estructural Compuesta (SCL) – LVL, PSL, LSL, OLS –",
    "Pino Amarillo tratado",
    "Triplay /Contrachapado",
    "Vigas I",
    "Cross-Laminated Timber (CLT)",
    "**No he trabajado con paneles/madera de E.U.",
  ];
  const q5 = [
    "Información de contacto de compañías de E.U.",
    "Información y usos de los diferentes paneles y maderas de coníferas de E.U.",
    "Información sobre códigos / estándares",
    "Información sobre sostenibilidad de los productos de madera de E.U.",
    "Soporte técnico",
    "Información general sobre el mercado de E.U.",
    "Estadísticas de la industria forestal de E.U.",
    "Información de distribuidores locales",
  ];
  const authors_options = ["Excelente", "Bueno", "Regular", "Malo"];
  const apa_options = [
    "Totalmente",
    "Mucho",
    "Regular",
    "Poco",
    "Nada",
  ];
  const course_questions = [
    "¿Cómo califica el contenido general del seminario?",
    "¿Tiene usted un adecuado conocimiento de los paneles estructurales de E.U., en cuando a su calidad y grado para darle un uso correcto en su compañía?",
  ]
  const course_options = ["Excelente", "Muy bueno", "Bueno", "Más o menos", "Nada"];
  const apa_questions = [
    "¿Sabía que los paneles estructurales de E.U. están disponibles para el uso de su compañía?",
    "¿Sabía usted que el valor y calidad que los paneles estructurales de E.U. pueden darle ventaja a su compañía a través de un uso óptimo?",
    "¿Sabía de la importancia del certificado de los paneles de E.U. y el rol que desempeñan para asegurar la integridad estructural y uso a largo plazo en construcciones?",
    "¿Sabía que los paneles de E.U. representan un buen valor monetario y podrían ser una mejor alternativa en comparación con otros materiales constructivos como el acero u hormigón?",
    "¿Sabía que los paneles de E.U. están reconocidos internacionalmente en los códigos de construcción por el importante rol que juegan en construcciones seguras y en la aplicación de sistemas constructivos? ",
    "¿Sabía que los paneles estructurales de E.U. son fabricados con madera de bosques templados que reducen las emisiones de carbono y son más amigables con el medio ambiente?",
    "¿Su entendimiento sobre los paneles estructurales americanos y sus usos se incrementó después de tener contacto con American Softwoods y APA?",
    "¿Qué tan probable es que su compañía especifique o compre más paneles de E.U. después de asistir a este seminario?",
  ];


  return (
    <div className="report mb-5">
      <Row>
        <Col xs={12} md={{ span: 10, offset: 1 }}>
          <span>
            <h1>Detalle de encuesta</h1>
            <p><b>Curso:</b> { course ? course.title : ''}</p>
            <p><b>Usuario:</b> { data.user ? data.user.name+" "+data.user.lastname : ''}</p>
            <span>
                <Form>
                  <Form.Group controlId="question1">
                    <Form.Label>
                      ¿Es usted o la compañía donde trabaja importadores de
                      paneles o madera?
                    </Form.Label>
                    <Form.Check
                      type="radio"
                      label="Si"
                      value="Si"
                      name="q1"
                      id="q1-s"
                      checked={ data.q1 == "Si" ? true :false }
                      disabled={true}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      value="No"
                      name="q1"
                      id="q1-n"
                      checked={ data.q1 == "No" ? true :false }
                      disabled={true}
                    />
                    <Form.Check
                      type="radio"
                      label="Podría importar"
                      value="podria"
                      name="q1"
                      id="q1-p"
                      checked={ data.q1 == "podria" ? true :false }
                      disabled={true}
                    />
                  </Form.Group>
                  <Form.Group controlId="question2">
                    <Form.Label>
                      *En caso de que sí, ¿de qué países y qué producto importa
                      o han importado?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="q2"
                      defaultValue={ data.q2 }
                      disabled={true}
                    />
                  </Form.Group>
                  <Form.Group controlId="question3">
                    <Form.Label>
                      ¿A qué se dedica usted o la empresa que representa?
                    </Form.Label>
                    {q3.map((item: any, key: any) => (
                      <Form.Check
                        type="checkbox"
                        label={item}
                        value={item}
                        name="q3"
                        id={`q3-${key}`}
                        key={`q3-${key}`}
                        checked={ data.q3 && data.q3.includes(item) ? true : false }
                        disabled={true}
                      />
                    ))}
                    <Form.Check
                      type="radio"
                      label="Otro"
                      value="Otro"
                      name="q3"
                      id="q3-15"
                    />
                    <Form.Control
                      type="text"
                      name="q3_otro"
                      placeholder="Otro..."
                    />
                  </Form.Group>
                  <Form.Group controlId="question4">
                    <Form.Label>
                      ¿Con qué tipo de panel o madera de E.U. ha trabajado o
                      conoce?
                    </Form.Label>
                    {q4.map((item: any, key: any) => (
                      <Form.Check
                        type="checkbox"
                        label={item}
                        value={item}
                        name="q4"
                        id={`q4-${key}`}
                        key={`q4-${key}`}
                        checked={ data.q4 && data.q4.includes(item) ? true : false }
                        disabled={true}
                      />
                    ))}
                    <Form.Control
                      type="text"
                      name="q4_otro"
                      placeholder="Otro..."
                      defaultValue={ data.q4_otro }
                      disabled={true}
                    />
                  </Form.Group>
                  <Form.Group controlId="question5">
                    <Form.Label>
                      ¿Qué tipo de información o materiales podría American
                      Softwoods brindarle para fomentar que usted use o
                      especifique los paneles o madera de pino amarillo de E.U.?
                    </Form.Label>
                    {q5.map((item: any, key: any) => (
                      <Form.Check
                        type="checkbox"
                        label={item}
                        value={item}
                        name="q5"
                        id={`q5-${key}`}
                        key={`q5-${key}`}
                        checked={ data.q5 && data.q5.includes(item) ? true : false }
                        disabled={true}
                      />
                    ))}
                    <Form.Control
                      type="text"
                      name="q5_otro"
                      placeholder="Otro..."
                      defaultValue={ data.q5_otro }
                      disabled={true}
                    />
                  </Form.Group>
                  <Form.Group controlId="about-seminar">
                    <Form.Label>
                      ¿Cómo califica la calidad de los siguientes aspectos del
                      seminario?
                    </Form.Label>
                        <div className="questions">
                          <div className="question">Exposición</div>
                          <div className="options">
                            {authors_options.map((option, okey) => (
                              <Form.Check
                                key={`author-${okey}-q1-${option}`}
                                inline
                                name={`expo`}
                                label={option}
                                value={option}
                                type="radio"
                                id={`author-${okey}-q1-${option}`}
                                checked={ data[`expo`] == option ? true : false }
                                disabled={true}
                              />
                            ))}
                          </div>
                        </div>
                        <div className="questions">
                          <div className="question">Presentación</div>
                          <div className="options">
                            {authors_options.map((option, okey) => (
                              <Form.Check
                                key={`author-${okey}-q2-${option}`}
                                inline
                                name={`prese`}
                                label={option}
                                value={option}
                                type="radio"
                                id={`author-${okey}-q2-${option}`}
                                checked={ data[`prese`] == option ? true : false }
                                disabled={true}
                              />
                            ))}
                          </div>
                        </div>

                    <div className="expositor">
                      <div className="name">Formato del Seminario</div>
                      <div className="questions">
                        <div className="options">
                          {authors_options.map((option) => (
                            <Form.Check
                              key={`format-${option}`}
                              inline
                              name="format"
                              label={option}
                              value={option}
                              type="radio"
                              id={`format-${option}`}
                              checked={ data[`format`] == option ? true : false }
                              disabled={true}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="comments">
                    <Form.Label>
                      Con la finalidad de darle un mejor servicio y mejorar
                      nuestras actividades, le pedimos que nos deje sus
                      comentarios sobre el seminario.
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="comments"
                      rows="3"
                      defaultValue={ data.comments }
                      disabled={true}
                    />
                  </Form.Group>
                  <Form.Group controlId="apa">
                    <Form.Label>Conteste con una evaluación:</Form.Label>
                    {course_questions.map((question: any, key: any) => (
                      <div className="expositor" key={`apa-${key}`}>
                        <div className="name">{question}</div>
                        <div className="questions">
                          <div className="options">
                            {course_options.map((option: any, okey: any) => (
                              <Form.Check
                                key={`apa-${okey}`}
                                inline
                                name={`apa_${key}`}
                                label={option}
                                value={option}
                                type="radio"
                                id={`apa-${key}-${okey}`}
                                checked={ data[`apa_${key}`] == option ? true : false }
                                disabled={true}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                    {apa_questions.map((question: any, key: any) => (
                      <div className="expositor" key={`apa-${key}`}>
                        <div className="name">{question}</div>
                        <div className="questions">
                          <div className="options">
                            {apa_options.map((option: any, okey: any) => (
                              <Form.Check
                                key={`apa-${okey}`}
                                inline
                                name={`apa_${key+2}`}
                                label={option}
                                value={option}
                                type="radio"
                                id={`apa-${key+2}-${okey}`}
                                checked={ data[`apa_${key+2}`] == option ? true : false }
                                disabled={true}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Form.Group>
                </Form>
            </span>
          </span>
        </Col>
      </Row>
    </div>
  );
}

export default ReportView;
