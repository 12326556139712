import React, { useState, useEffect, useRef } from "react";
import "../../styles/course.scss";
import { Row, Col, ListGroup, Form } from "react-bootstrap";
import { useParams } from "react-router";
import { request } from "../../services/Request";

function Course() {
  const { id } = useParams();
  const [topics, setTopics]: any = useState([]);
  const [course, setCourse]: any = useState({ title: "" });
  const [currentView, setCurrentView]: any = useState("course");
  const [selectedTopic, setSelected]: any = useState({
    title: "",
    description: "",
  });
  const [lastTopic, setLastTopic]: any = useState({
    topic: "",
    last_view: "",
    duration: "",
  });

  const getTopics = () => {
    request("/api/topic?course=" + id, {})
      .then((result: any) => {
        if (result.error) {
        } else {
          setTopics(result.data);
        }
      })
      .catch((error) => {});
  };

  const getCourse = () => {
    request("/api/course/" + id, {})
      .then((result: any) => {
        if (result.error) {
        } else {
          setCourse(result.data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getTopics();
    getCourse();
    /*window.addEventListener('contextmenu', function (e) { 
      // do something here... 
      e.preventDefault(); 
    }, false);*/
  }, [id]);

  const showTopic = (e: any, item: any) => {
    e.preventDefault();
    setCurrentView("course");
    if (selectedTopic.title) {
      showVideo(item, '');
    } else {
      setSelected(item);
      if (item.title !== "Portada") {
        setTimeout(() => {
          showVideo(item, '');
        }, 100);
      }
    }
  };

  const showVideo = (item: any, lang:string, last_view?:number) => {
    console.log(item);
    let video_container = document.getElementById(
      "video-container"
    ) as HTMLElement;
    if (document.querySelector("video")) {
      video_container.innerHTML = "";
    }
    let video = document.createElement("video") as HTMLVideoElement;
    video.src = "/api/topic/stream/" + item._id +"?lang="+ ( lang != '' ? lang : item.videos[0].saved_name );
    video.style.width = "100%";
    video.controls = true;
    video.setAttribute("controlsList","nodownload");
    video_container.appendChild(video);
    let flag = false;
    let supposedCurrentTime = 0;
    video.addEventListener("timeupdate", function () {
      if (!video.seeking) {
        supposedCurrentTime = video.currentTime;
        let data = lastTopic;
        data["last_view"] = video.currentTime;
        data["duration"] = video.duration;
        data["topic"] = item._id;
        setLastTopic(data);
      }
    });
    // prevent user from seeking
    video.addEventListener("seeking", function () {
      if (!flag && video.currentTime > supposedCurrentTime) {
        video.currentTime = supposedCurrentTime;
      } else {
        flag = false;
      }
    });
    setTimeout(() => {
      flag = true;
      supposedCurrentTime = last_view ? last_view :  0;
      video.currentTime = last_view ? last_view :  0;
    }, 1000);
  };

  const setLang = (e:any) => {
    showVideo(selectedTopic, 
      (document.querySelector("select[name=lang]") as HTMLInputElement).value,
      lastTopic.last_view);
  }

  return (
    <div className="course-preview pb-5">
      <Row>
        <Col xs={12} className="mt-5">
          <Row>
            <Col xs={12} md={2} className="mt-5">
              <ListGroup defaultActiveKey="#linkPortada">
                <ListGroup.Item
                  action
                  href="#linkPortada"
                  onClick={(e: any) => {
                    showTopic(e, { title: "Portada" });
                  }}
                >
                  Portada
                </ListGroup.Item>
                {topics.map((item: any, index: number) => (
                  <ListGroup.Item
                    action
                    href={"#link" + index}
                    key={index}
                    onClick={(e: any) => {
                      showTopic(e, item);
                    }}
                  >
                    {item.title}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
            <Col xs={12} md={10} className="mt-5">
              {currentView === "course" &&
                (selectedTopic.title === "" ||
                  selectedTopic.title === "Portada") && (
                  <img
                    src={`/videos/image_${course._id}.jpg`}
                    className="w-50"
                    alt="Course"
                  />
                )}
              {currentView === "course" &&
                selectedTopic.title !== "" &&
                selectedTopic.title !== "Portada" && (
                  <div>
                    <h2 className="mt-5 mb-5">{selectedTopic.title}</h2>
                    <p>{selectedTopic.description}</p>
                    <Form>
                      <Form.Group controlId="lang">
                        <Form.Label>Seleccionar idioma</Form.Label>
                        <Form.Control as="select" name="lang" onChange={setLang}>
                          {  selectedTopic.videos.map((item:any,index:number)=>(
                              <option value={item.saved_name}>{item.lang}</option>
                            ))
                          }
                        </Form.Control>
                      </Form.Group>
                    </Form>
                    <div
                      style={{ position: "relative" }}
                      id="video-container"
                    ></div>
                  </div>
                )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Course;
