import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Form, Button, Container, Toast } from "react-bootstrap";
import { request } from "../../../services/Request";
import { useParams } from "react-router";
import Loader from "react-loader-spinner";
import { Trash } from "react-bootstrap-icons";

function ManageEditTopic() {
  const [selectedFiles, setFiles]:any = useState([]);
  const [topic, setTopic]: any = useState({
    videos: []
  });
  const [list, setList]:any = useState([]);
  const { id, topicId } = useParams();
  const [changeVideo, setVideo] = useState(false);
  const [loading, setLoading]: any = useState(false);
  const [alertContent, setContent]: any = useState({
    title: "",
    body: "",
  });
  const [showAlert, setAlert] = useState(false);
  const [removeIndex, setIndex] = useState(-1);
  const [updateVideoElement, setUpdate] = useState({
    index: -1,
    value: ''
  })

  useEffect(()=>{
    request("/api/topic/"+topicId,{})
    .then((result:any)=>{
      if(result.data){
        setTopic(result.data);
        let items:any = [];
        result.data.videos.forEach((item:any,index:number)=>{
          console.log(item);
          items.push(
            <div id={"row-"+(index)}>
               <hr/>
              <Row>
                <Col sm={{offset:2}}>
                  <Form.Group as={Row} controlId="formHorizontalImage">
                    <Form.Label column sm={4}>
                      Video
                    </Form.Label>
                    <Col sm={{offset:1}}>
                      <video className="w-100" src={"/videos/video_"+topicId+"_"+item["saved_name"]+".mp4"} controls></video>                           
                    </Col>
                  </Form.Group>                  
                </Col>
                <Col>
                  <Form.Group as={Row} controlId={"lang-"+index}>
                    <Form.Label column sm={2}>
                      Idioma
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        name="lang"
                        onChange={ (event:any) => updateVideo(event, index) }
                        defaultValue={ item["lang"] }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={1}>                 
                  <a onClick={ (event:any) => remove(event, index)} className="btn btn-danger">
                    <Trash/>
                  </a>
                </Col>
              </Row>
             </div> 
          );
        });
        setList([... items]);
      }
    }).catch((error:any)=>{

    });
  }, []);

  useEffect(() => {
    console.log(list, topic);
  }, [list, topic]);

  useEffect(()=>{
    console.log("Actualizar elemento de video");
    if(updateVideoElement.index > -1){
      console.log(topic, updateVideoElement);
      let data = topic;
      data["videos"][updateVideoElement.index]["lang"] = updateVideoElement.value;
      setTopic({...data});      
    }
  }, [updateVideoElement]);

  useEffect(() => {
    //console.log(list, removeIndex);
    let items = [];
    items = list.filter((item:any, index:number)=>{
      //console.log(removeIndex, index);
      return removeIndex != index;
    });
    //console.log(items);
    setList([...items]);
    let data = topic;
    console.log(data);
    data.videos = data.videos.filter( (item:any, indexArray:number) => {
      return removeIndex != indexArray;
    });
    setTopic({...data});
  }, [removeIndex]);

  const onSubmitHandler = (event: any) => {
    event.preventDefault();
    setLoading(true);
    let data = new FormData();
    for (var x = 0; x < selectedFiles.length ; x++) {
      data.append("file[]", selectedFiles[x]);
    }
    for (const field in topic) {
      if(field == 'videos'){
        data.append("videos", JSON.stringify( topic[field] ) );
      }else{
        data.append(field, topic[field]);
      }
    }
    request("/api/topic/"+topicId, {
      method: "PUT",
      body: data,
    })
      .then((result: any) => {
        if(result.error){
          setLoading(false);
          setContent({
            title: "Error",
            body: "Ocurrió un error. Favor de intentarlo más tarde.",
          });
          setAlert(true);
        }else{          
          window.location.replace("/manage/courses/" + id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addVideo = () => {
    console.log("Agregar video");
    let data = topic;
    if(!data.videos){
      data.videos = [];
    }
    data.videos.push({
      name: '',
      lang: ''
    });
    console.log(data);
    setTopic({...data});
    let items:any = list;
          items.push(
            <div id={"row-"+(list.length - 1)}>
               <hr/>
              <Row>
                <Col sm={{offset:2}}>
                  <Form.Group as={Row} controlId="formHorizontalImage">
                    <Form.Label column sm={4}>
                      Video
                    </Form.Label>
                    <Col sm={{offset:1}}>
                        <Form.File
                          id="video"
                          name="video"
                          label="MP4"
                          onChange={(event:any) => updateVideo(event, list.length - 1)}
                        />
                    </Col>
                  </Form.Group>                  
                </Col>
                <Col>
                  <Form.Group as={Row} controlId={"lang-"+(list.length - 1)}>
                    <Form.Label column sm={2}>
                      Idioma
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        name="lang"
                        onChange={ (event:any) => updateVideo(event, list.length - 1) }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={1}>                 
                  <a onClick={ (event:any) => remove(event, list.length - 1)} className="btn btn-danger">
                    <Trash/>
                  </a>
                </Col>
              </Row>
             </div> 
          );
        setList([...items]);
  }

  const updateVideo = (event:any, index:number) => {
    let data = topic;
    if (event.target.name === "video") {
      data.videos[index]["name"] = event.target.files[0].name;
      onChangeHandler(event);
      setTopic(data);
    }else{
      console.log(data);
      //data.videos[index][ event.target.name ] = event.target.value;
      setUpdate({
        index: index,
        value: event.target.value
      });
    }
  }

  const onChangeHandler = (event: any) => {
    if (event.target.name === "video") {
      let files = selectedFiles;
      files.push(event.target.files[0]);
      setFiles(files);
    } else {
      let data = topic;
      data[event.target.name] = event.target.value;
      setTopic(data);
    }
  };

  const remove = (event:any, index:number) => {
    console.log(list);    
    let videos = selectedFiles;
    videos.splice(index,1);
    setFiles([...videos]);
    setIndex(index);
  }

  return (
    <div className="manage new-topic">
      <div className={"loader-container " + (loading ? "d-flex" : "d-none")}>
        <Row className="pt-5 pb-5 justify-content-center w-100">
          <Col xs={12} md={4}>
            <Loader
              type="Oval"
              color="#439a86"
              visible={true}
              width={300}
              height={300}
            />
          </Col>
        </Row>
      </div>
      <Container>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group as={Row} controlId="formHorizontalTitle">
            <Form.Label column sm={2}>
              Tema
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="title"
                placeholder="Título"
                onChange={onChangeHandler}
                defaultValue={topic.title}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formHorizontalDescription">
            <Form.Label column sm={2}>
              Descripción
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                name="description"
                as="textarea"
                rows="3"
                onChange={onChangeHandler}
                defaultValue={topic.description}
              />
            </Col>
          </Form.Group>
          <a className="btn btn-secondary" onClick={addVideo}> Agregar video</a> 
          <div id="video-list">
            {
              list
            }   
          </div>
          <Form.Group as={Row}>
            <Col sm={{ span: 10, offset: 2 }}>
              <Button type="submit">Guardar</Button>
            </Col>
          </Form.Group>
        </Form>
      </Container>
      <Toast
        onClose={() => setAlert(false)}
        show={showAlert}
        className={showAlert ? "d-block" : "d-none"}
      >
        <Toast.Header>
          <strong className="mr-auto">{alertContent.title}</strong>
        </Toast.Header>
        <Toast.Body>{alertContent.body}</Toast.Body>
      </Toast>
    </div>
  );
}

export default ManageEditTopic;
