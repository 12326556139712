import React, { useEffect, useState } from "react";
import "../styles/header.scss";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { request } from "../services/Request";

function Header() {
  const [isLoggedIn, setLogIn] = useState(false);
  const [userData, setData]: any = useState({
    role: 2,
    permissions:{}
  });

  useEffect(() => {
    if(localStorage.user){
      request("/api/auth/me", { noRedirect: true })
        .then((result: any) => {
          if (result.data) {
            setLogIn(true);
            setData(result.data);
          }
        })
        .catch((error) => {});
    }
  }, []);

  const logout = (e: any) => {
    e.preventDefault();
    delete localStorage.user;
    delete localStorage.token;
    window.location.href = "https://americansoftwoodslatam.com/";
  };

  return (
    <header className="header fixed-top">
      <Navbar collapseOnSelect expand="md" bg="light" variant="light">
        <Navbar.Brand>
          <a href="https://americansoftwoodslatam.com/">
            <img src="/asw.jpg" alt="American Softwoods" className="logo" />
          </a>
          <a href="/dashboard/all-courses" className="text-dark">
            Videoteca
          </a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/dashboard/all-courses">Inicio</Nav.Link>
            {!isLoggedIn && <Nav.Link href="/login">Inicio sesión</Nav.Link>}
            {isLoggedIn && userData.permissions.users && userData.role == 1 && (
              <Nav.Link href="/manage/users">Administrar usuarios</Nav.Link>
            )}
            {isLoggedIn && userData.permissions.users && userData.role == 1 && (
              <Nav.Link href="/manage/customers">Administrar clientes</Nav.Link>
            )}
            {isLoggedIn && userData.permissions.authors && userData.role == 1 && (
              <Nav.Link href="/manage/authors">Administrar autores</Nav.Link>
            )}
            {isLoggedIn && userData.permissions.categories && userData.role == 1 && (
              <Nav.Link href="/manage/categories">Administrar categorías</Nav.Link>
            )}
            {isLoggedIn && userData.permissions.courses && userData.role == 1 && (
              <Nav.Link href="/manage/courses">Administrar cursos</Nav.Link>
            )}
            {isLoggedIn && userData.permissions.reports && userData.role == 1 && (
              <Nav.Link href="/report/list">Reporte</Nav.Link>
            )}
            {isLoggedIn && (
              <NavDropdown
                title={userData.name + " " + userData.lastname}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item onClick={logout}>
                  Cerrar sesión
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default Header;
