export const request = (url:string, options:any) => {

  function handleErrors(response:any) {
      if(response.status === 403 && !options.noRedirect){
        delete localStorage.user;
        delete localStorage.token;
        window.location.href = "/login";
      }else if (!response.ok) {
        //console.log(response);
        return response.text().then((text:any) => {
          response.message = text
          throw response;
        })
      }
      return response;
  }
  if(options['headers']){
    options['headers']['Authorization'] = "Bearer "+localStorage.token;
  }else{
    options['headers'] = {
      'Authorization' : "Bearer "+localStorage.token
    }
  }

  return fetch(url, options)
  .then( handleErrors)
  .then( (response) => { return response.json() })
  .then(
    (result) => {
      return { data: result };
    }
  )
  .catch(
    (error) => {
      console.log(error, error.error, url);
      if( (error.status && error.status != 403 && error.status != 404) && url != '/api/auth/signin' && url != '/api/client/retrievePassword'){
        sendError(error, options, url);
      }
      return { error: error };
    }
  )
}

export const refreshToken = () => {
  function handleErrors(response: any) {
    if (!response.ok) {
      return response.text().then((text: any) => {
        response.message = text;
        throw response;
      });
    }
    return response;
  }

  let options: any = { headers: {}, method: "GET" };
  options["headers"] = {
    Authorization: "Bearer " + localStorage.token,
  };

  return fetch("/api/auth/me", options)
    .then(handleErrors)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      localStorage.token = result.token;
      return result;
    })
    .catch((error) => {
      console.log(error, error.error);
    });
};

export const sendError = (error:any, options:any, url:string) => {
  function handleErrors(response:any) {
      if(response.status === 403 && !options.noRedirect){
        delete localStorage.user;
        delete localStorage.token;
        window.location.href = "/login";
      }else if (!response.ok) {
          throw Error(response.statusText);
      }
      return response;
  }

  //console.log(error);

  let body:any = {};
  if( Object.keys(options.body).length < 1 ){
    for (var pair of options.body.entries()) {
        //console.log(pair[0]+ ', ' + pair[1]); 
        body[ pair[0] ] = pair[1];
    }
  }else{
    body = options.body;
  }


  return fetch("/api/auth/error", {
    method:'POST',
    headers: { 
      "Content-Type": "application/json" ,
      'Authorization' : "Bearer "+localStorage.token
    },
    body: JSON.stringify({
      error: {
        status: error.status,
        error: error.message
      },
      routes:{
        current: window.location.href
      },
      request: {
        url: url,
        body: body,
        method: options.method
      }
    })
  })
  .then( handleErrors )
  .then( (response) => { return response.json() })
  .then(
    (result) => {
      return { data: result };
    }
  )
  .catch(
    (error) => {
      console.log(error);
      return { error: error };
    }
  )
}

export const checkPermissions = (permission:string) => {
  function handleErrors(response: any) {
    if (response.status === 403 && !options.noRedirect) {
      delete localStorage.user;
      delete localStorage.token;
      window.location.href = "/login";
    } else if (!response.ok) {
      window.location.href = "/";
    }
    return response;
  }

  let options: any = { headers: {}, method: "GET" };
  options["headers"] = {
    Authorization: "Bearer " + localStorage.token,
  };

  return fetch("/api/auth/me", options)
    .then(handleErrors)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if(result.permissions[permission]){
        return true;
      }else{
        window.location.href = "/";
      }
    })
    .catch((error) => {
      console.log(error, error.error);
    });
};