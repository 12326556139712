import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Form, Button, Toast } from "react-bootstrap";
import { request } from "../../../services/Request";
import { useParams } from "react-router";
import Loader from "react-loader-spinner";
import Select from "react-select";

function ManageEditCourse() {
  const selectedFile = useRef("");
  const [course, setCourse]: any = useState({});
  const [authors, setAuthors]: any = useState([]);
  const [categories, setCategories ]:any = useState([]);
  const [selectedAuthors, setSelectedAuthor]: any = useState([]);
  const [selectedCategories, setSelectedCategories]:any = useState([]);
  const [mainAuthor, setMainAuthor]: any = useState({ label: "", value: "" });
  const { id } = useParams();
  const [showAlert, setAlert] = useState(false);
  const [changeImage, setImage] = useState(false);
  const [loading, setLoading]: any = useState(false);
  const [alertContent, setContent]: any = useState({
    title: "",
    body: "",
  });
  const [filename, setFile] = useState("");

  useEffect(() => {
    setLoading(true);
    request("/api/course/" + id, {})
      .then((result: any) => {
        if (result.data) {
          if(result.data.issue_certificate){
            (document.querySelector('#issue_certificate') as HTMLInputElement).checked = true;
          }
          if(result.data.issue_survey){
            (document.querySelector('#issue_survey') as HTMLInputElement).checked = true;
          }
          if(result.data.published){
            (document.querySelector('#published') as HTMLInputElement).checked = true;
          }
          setCourse(result.data);          
          getAuthors(result.data.authors, result.data.main_author);
          getCategories(result.data.categories);
        }
      })
      .catch((error: any) => {});
  }, []);

  const getCategories = (currentCategories:any) => {
    request("/api/category",{})
    .then((result:any)=>{
      if(result.data){
        setCategories(
          result.data.map((category: any) => {
            return { value: category.name, label: category.name };
          })
        );     
        setSelectedCategories( 
          currentCategories.map((category: any) => {
            return { value: category, label: category };
          }) 
        );   
        console.log(currentCategories.map((category: any) => {
            return { value: category, label: category };
          }) );
      }
    }).catch((error:any)=>{

    });
  }

  const getAuthors = (currentAuthors: any, currentMainAuthor: any) => {
    request("/api/author", {})
      .then((result: any) => {
        if (result.data) {
          setAuthors(
            result.data.map((author: any) => {
              return { value: author._id, label: author.name };
            })
          );
          setSelectedAuthor(
            result.data
              .map((author: any) => {
                return { value: author._id, label: author.name };
              })
              .filter((author: any) => {
                return currentAuthors.includes(author.value) || currentMainAuthor === author.value;
              })
          );
          setMainAuthor(
            result.data
              .map((author: any) => {
                return { value: author._id, label: author.name };
              })
              .filter((author: any) => {
                return currentMainAuthor === author.value;
              })[0]
          );
          setLoading(false);
        }
      })
      .catch((error: any) => {});
  };

  const onSubmitHandler = (event: any) => {
    setLoading(true);
    event.preventDefault();
    let data = new FormData();
    data.append("file", selectedFile.current);

    for (const field in course) {
      if (field === "authors") {
        data.append(
          "authors",
          selectedAuthors.map((author: any) => {
            return author.value;
          })
        );
      } else if (field === "main_author") {
        data.append("main_author", mainAuthor ? mainAuthor.value : null);
      } else if (field != "_id") {
        data.append(field, course[field]);
      }
    }
    request("/api/course/" + id, {
      method: "PUT",
      body: data,
    })
      .then((result: any) => {
        if (result.error) {
          setContent({
            title: "Error",
            body: "Ocurrió un error. Favor de intentarlo más tarde.",
          });
          setAlert(true);
          setLoading(false);
        } else {
          window.location.replace("/manage/courses");
        }
      })
      .catch((error) => {
        console.log(error);
        setContent({
          title: "Error",
          body: "Ocurrió un error. Favor de intentarlo más tarde.",
        });
        setAlert(true);
        setLoading(false);
      });
  };

  const onChangeHandler = (event: any) => {
    if (typeof event.target != "undefined" && event.target.name === "imagen") {
      selectedFile.current = event.target.files[0];
      setFile((event.target.files[0] as HTMLInputElement).name);
    }else if(event.target.name == "issue_certificate" 
      || event.target.name == "issue_survey"
      || event.target.name == "published"){
      let data = course;
      if( data[event.target.name] ){
        data[event.target.name] = false;
      }else{
        data[event.target.name] = event.target.value;
      }
      setCourse(data);
    } else {
      let data = course;
      data[event.target.name] = event.target.value;
      console.log("onchange", event.target);
      setCourse(data);
    }
  };

  const onChangeHandlerAuthors = (data: any) => {
    setMainAuthor( data ? data[0] : {value:null} );
    setSelectedAuthor(data ? data : []);
  };

  const onChangeHandlerMainAuthor = (data: any) => {
    setMainAuthor(data);
  };

  const upload = () => {
    (document.getElementById("imagen") as HTMLInputElement).click();
  };

  const onChangeHandlerCategories = (data: any) => {
    setSelectedCategories(data);
    if(data){      
      let courseData = course;
      courseData["categories"] = data.map((item:any) => {
        return item.value;
      });
      console.log(data, courseData["categories"]);
      setCourse(courseData);
    }
  };

  return (
    <div className="manage new-course">
      <div className={"loader-container " + (loading ? "d-flex" : "d-none")}>
        <Row className="pt-5 pb-5 justify-content-center w-100">
          <Col xs={12} md={4}>
            <Loader
              type="Oval"
              color="#439a86"
              visible={true}
              width={300}
              height={300}
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <h2>Editar curso</h2>
          <Form onSubmit={onSubmitHandler}>
            <Form.Group as={Row} controlId="formHorizontalTitle">
              <Form.Label column sm={2}>
                Título
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Título"
                  onChange={onChangeHandler}
                  defaultValue={course.title}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalDescription">
              <Form.Label column sm={2}>
                Descripción Corta
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="description"
                  as="textarea"
                  rows="3"
                  onChange={onChangeHandler}
                  defaultValue={course.description}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalDescriptionLong">
              <Form.Label column sm={2}>
                Descripción Larga
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="long_description"
                  as="textarea"
                  rows="3"
                  onChange={onChangeHandler}
                  defaultValue={course.long_description}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalAuthors">
              <Form.Label column sm={2}>
                Autor
              </Form.Label>
              <Col sm={10}>
                <Select
                  options={authors}
                  isMulti
                  name="authors"
                  value={selectedAuthors}
                  onChange={onChangeHandlerAuthors}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalMainAuthor">
              <Form.Label column sm={2}>
                Categorías
              </Form.Label>
              <Col sm={10}>
                <Select
                  options={categories}
                  name="categories"
                  isMulti
                  onChange={onChangeHandlerCategories}
                  value={selectedCategories}
                />
              </Col>
            </Form.Group>
            {selectedAuthors.length > 1 && (
              <Form.Group as={Row} controlId="formHorizontalMainAuthor">
                <Form.Label column sm={2}>
                  Autor Principal
                </Form.Label>
                <Col sm={10}>
                  <Select
                    options={selectedAuthors}
                    name="main_author"
                    value={mainAuthor}
                    onChange={onChangeHandlerMainAuthor}
                  />
                </Col>
              </Form.Group>
            )}
            <br/>
            <Form.Check 
            className="ml-3"
              type={"checkbox"}
              id={`issue_certificate`}
              name="issue_certificate"
              label={`Expedir certificado`}
              value="true"
              defaultChecked={ course.issue_certificate ? true : false }
              onChange={onChangeHandler}
            />
            <br/>
            <Form.Check 
            className="ml-3"
              type={"checkbox"}
              id={`issue_survey`}
              name="issue_survey"
              label={`Enviar encuesta`}
              value="true"
              defaultChecked={ course.issue_survey ? true : false }
              onChange={onChangeHandler}
            />
            <br/>

            <br />
            {changeImage == false && (
              <Row>
                <Col xs={12} md={2}>
                  Imagen
                </Col>
                <Col xs={12} md={4}>
                  <img
                    src={"/videos/image_" + course._id + ".jpg"}
                    className="w-100"
                  />
                  <a
                    onClick={() => setImage(true)}
                    className="btn btn-info text-white"
                  >
                    Cambiar imagen
                  </a>
                </Col>
              </Row>
            )}
            {changeImage == true && (
              <Form.Group as={Row} controlId="formHorizontalImage">
                <Form.Label column sm={2}>
                  Imagen
                </Form.Label>
                <Col sm={10}>
                  <p>
                    Subir una imagen descriptiva. Tamaño recomendado de 600x300
                    pixeles.
                  </p>
                  <div className="d-flex align-items-center">
                    <a className="text-white btn btn-info" onClick={upload}>
                      Subir archivo
                    </a>
                    <p className="m-0 ml-3">{filename}</p>
                  </div>
                  <Form.File
                    id="imagen"
                    name="imagen"
                    label="Imagen descriptiva - 600x300"
                    onChange={onChangeHandler}
                    style={{ visibility: "hidden" }}
                  />
                </Col>
              </Form.Group>
            )}            
            <br/>
            <Form.Check 
            className="ml-3"
              type={"checkbox"}
              id={`published`}
              name="published"
              label={`¿Publicar curso?`}
              value="true"
              onChange={onChangeHandler}
              defaultChecked={ course.published ? true : false }
            />
            <br/>  
            <Form.Group as={Row}>
              <Col sm={{ span: 10, offset: 2 }}>
                <Button type="submit">Guardar</Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Toast
        onClose={() => setAlert(false)}
        show={showAlert}
        className={showAlert ? "d-block" : "d-none"}
      >
        <Toast.Header>
          <strong className="mr-auto">{alertContent.title}</strong>
        </Toast.Header>
        <Toast.Body>{alertContent.body}</Toast.Body>
      </Toast>
    </div>
  );
}

export default ManageEditCourse;
