import React, { useRef, useState } from "react";
import { Row, Col, Form, Button, Container, Toast } from "react-bootstrap";
import { request } from "../../../services/Request";
import Loader from "react-loader-spinner";

function ManageNewAuthor() {
  const selectedFile = useRef("");
  const [author, setAuthor]: any = useState({});
  const [showAlert, setAlert] = useState(false);
  const [loading, setLoading]: any = useState(false);
  const [alertContent, setContent]: any = useState({
    title: "",
    body: "",
  });
  const [filename, setFile] = useState("");

  const onSubmitHandler = (event: any) => {
    setLoading(true);
    event.preventDefault();
    let data = new FormData();
    data.append("file", selectedFile.current);
    for (const field in author) {
      data.append(field, author[field]);
    }
    request("/api/author", {
      method: "POST",
      body: data,
    })
      .then((result: any) => {
        if (result.error) {
          setContent({
            title: "Error",
            body: "Ocurrió un error. Favor de intentarlo más tarde.",
          });
          setAlert(true);
          setLoading(false);
        } else {
          window.location.replace("/manage/authors");
        }
      })
      .catch((error) => {
        console.log(error);
        setContent({
          title: "Error",
          body: "Ocurrió un error. Favor de intentarlo más tarde.",
        });
        setAlert(true);
        setLoading(false);
      });
  };
  const onChangeHandler = (event: any) => {
    if (event.target.name === "imagen") {
      selectedFile.current = event.target.files[0];
      setFile((event.target.files[0] as HTMLInputElement).name);
    } else {
      let data = author;
      data[event.target.name] = event.target.value;
      setAuthor(data);
    }
  };

  const upload = () => {
    (document.getElementById("imagen") as HTMLInputElement).click();
  };

  return (
    <div className="manage new-author">
      <div className={"loader-container " + (loading ? "d-flex" : "d-none")}>
        <Row className="pt-5 pb-5 justify-content-center w-100">
          <Col xs={12} md={4}>
            <Loader
              type="Oval"
              color="#439a86"
              visible={true}
              width={300}
              height={300}
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <h2>Nuevo Autor</h2>
          <Form onSubmit={onSubmitHandler}>
            <Form.Group as={Row} controlId="formHorizontalTitle">
              <Form.Label column sm={2}>
                Nombre Completo
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Nombre"
                  onChange={onChangeHandler}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalDescription">
              <Form.Label column sm={2}>
                Compañía
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="company"
                  type="text"
                  onChange={onChangeHandler}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalDescription">
              <Form.Label column sm={2}>
                Puesto
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="position"
                  type="text"
                  onChange={onChangeHandler}
                />
              </Col>
            </Form.Group>
            <br />
            <Form.Group as={Row}>
              <Col sm={{ span: 10, offset: 2 }}>
                <Button type="submit" className="">
                  Guardar
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Toast
        onClose={() => setAlert(false)}
        show={showAlert}
        className={showAlert ? "d-block" : "d-none"}
      >
        <Toast.Header>
          <strong className="mr-auto">{alertContent.title}</strong>
        </Toast.Header>
        <Toast.Body>{alertContent.body}</Toast.Body>
      </Toast>
    </div>
  );
}

export default ManageNewAuthor;
