import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Toast, Row, Col } from "react-bootstrap";
import { Trash, Pencil } from "react-bootstrap-icons";
import { request } from "../../../services/Request";
import Loader from "react-loader-spinner";

function ManageListCategories() {
  const [categories, setCategories]: any = useState([]);
  const [showModal, setModal] = useState(false);
  const [showAlert, setAlert] = useState(false);
  const [selected, setSelected]: any = useState({});
  const [loading, setLoading]: any = useState(false);
  const [alertContent, setContent]: any = useState({
    title: "",
    body: "",
  });

  const getCategories = () => {
    setLoading(true);
    request("/api/category", {})
      .then((result: any) => {
        if (result.error) {
          setLoading(false);
        } else {
          setCategories(result.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const remove = (item: any) => {
    setSelected(item);
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const confirmRemove = () => {
    request("/api/category/" + selected._id, {
      method: "DELETE",
    })
      .then((result: any) => {
        if (result.error) {
          setModal(false);
          setContent({
            title: "Eliminar categoría",
            body:
              "Ocurrió un error al realizar el proceso. Favor de intentarlo más tarde",
          });
          setAlert(true);
        } else {
          getCategories();
          setModal(false);
        }
      })
      .catch((error) => {
        setModal(false);
        setContent({
          title: "Eliminar categoría",
          body:
            "Ocurrió un error al realizar el proceso. Favor de intentarlo más tarde",
        });
        setAlert(true);
      });
  };

  return (
    <div className="manage list-categories">
      {loading ? (
        <Row className="pt-5 pb-5 justify-content-center w-100">
          <Col xs={12} md={4}>
            <Loader
              type="Oval"
              color="#439a86"
              visible={true}
              width={300}
              height={300}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }}>
            <h1>Categorías</h1>
            <a href="/manage/categories/new" className="btn btn-primary mt-5 mb-1">
              Nueva categoría
            </a>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <a href={`/manage/categories/` + item._id +"/edit"}>{item.name}</a>
                    </td>
                    <td>
                      <a
                        href={"/manage/categories/" + item._id + "/edit"}
                        title="Editar"
                        className="btn btn-info"
                      >
                        <Pencil />
                      </a>
                      <Button
                        variant="danger"
                        onClick={() => remove(item)}
                        title="Eliminar"
                      >
                        <Trash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Categoría</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Está seguro de eliminar la categoría "{selected.name}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={confirmRemove}>
            Sí
          </Button>
        </Modal.Footer>
      </Modal>
      <Toast
        onClose={() => setAlert(false)}
        show={showAlert}
        className={showAlert ? "d-block" : "d-none"}
      >
        <Toast.Header>
          <strong className="mr-auto">{alertContent.title}</strong>
        </Toast.Header>
        <Toast.Body>{alertContent.body}</Toast.Body>
      </Toast>
    </div>
  );
}

export default ManageListCategories;
