import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Toast } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "../../styles/login.scss";
import { request } from "../../services/Request";

function SetPassword() {
  const { token } = useParams();
  const [userData, setData]: any = useState({});
  const [error, setErrorData] = useState(false);

  useEffect(() => {
    request("/api/auth/getUser", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
    }).then((result: any) => {
      if (result.error) {
        window.location.href = "/login";
      } else {
        setData(result.data);
      }
    });
  }, [setData, token]);

  const onChangeHandlerFn = (event: any) => {
    // update the state;
    let currentData = userData;
    currentData[event.target.name] = event.target.value;
    setData(currentData);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    userData["token"] = token;
    const data = JSON.stringify(userData);
    const result = request("/api/auth/setpassword", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: data,
    });
    //console.log(result);
    result.then((data: any) => {
      //console.log(data);
      if (data.error) {
        console.log(data.error);
        setErrorData(true);
      } else {
        window.location.href = "/login";
      }
    });
  };

  return (
    <div className="login p-5 align-items-center justify-content-center d-flex">
      <Row className="w-100">
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <Card>
            <Card.Body className="p-0">
              <Card.Title className="p-3">
                <h2>Cambiar contraseña</h2>
              </Card.Title>
              <div className="pl-5 pr-5 pt-2 pb-2">
                <Card.Text>
                  <b>Email:</b>
                  <br />
                  {userData.email}
                </Card.Text>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="password">
                    <Form.Label>Nueva contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Contraseña"
                      name="password"
                      onChange={onChangeHandlerFn}
                    />
                  </Form.Group>
                  <Form.Group controlId="password2">
                    <Form.Label>Repetir contraseña contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Contraseña"
                      name="password2"
                      onChange={onChangeHandlerFn}
                    />
                  </Form.Group>
                  <Button variant="danger" type="submit" className="w-100">
                    Ingresar
                  </Button>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Toast
        style={{ position: "absolute", bottom: 0, right: 0 }}
        onClose={() => setErrorData(false)}
        show={error}
      >
        <Toast.Header>
          <strong className="mr-auto">Error</strong>
        </Toast.Header>
        <Toast.Body>
          Ocurrió un error al procesar los datos. Favor de intentarlo
          nuevamente.
        </Toast.Body>
      </Toast>
    </div>
  );
}

export default SetPassword;
