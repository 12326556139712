import React, { useState, useEffect } from "react";
import "../../styles/report.scss";
import { Row, Col, Card, Button, Nav, Table, Form, Alert } from "react-bootstrap";
import { request } from "../../services/Request";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useParams } from 'react-router';
import { Eye } from "react-bootstrap-icons";
import { Pie } from 'react-chartjs-2';
import Loader from "react-loader-spinner";

function ReportGeneral() {
  let currentDate = new Date();
  const [survey, setSurvey]: any = useState({});
  const [courses, setCourses]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCourse, setSelected]:any = useState({
    title:null
  });
  const [totalPeople, setTotalPeople] = useState(0);
  const [filters, setFilters]: any = useState({
    course_id: null,
    date_to: currentDate.toISOString().split("T")[0],
    date_from: new Date(currentDate.setMonth(currentDate.getMonth() - 1))
      .toISOString()
      .split("T")[0],
  });
  const { id } = useParams();
  const ref:React.RefObject<HTMLDivElement> = React.createRef();

  useEffect(() => {
    getCourses()
  }, []);

  const getCourses = () => {
    request("/api/course",{})
    .then((result:any)=>{
        if(result.data){
          setCourses(result.data);
        }
    })
  }

  const onChangeHandlerFn = (e: any) => {
    let data = filters;
    data[e.target.name] = e.target.value;
    if(e.target.name == 'course_id'){
      let filtered_course = courses.filter( (item:any) => {
        return e.target.value == item._id;
      });
      setSelected( filtered_course[0] );
    }
    setFilters(data);
  };

  const searchResults = () => {
    console.log(filters);
    setLoading(true);
    request(
      "/api/survey/report/"+ filters["course_id"] +"?date_from=" +
        filters["date_from"] +
        "&date_to=" +
        filters["date_to"],
      {}
    )
      .then((result: any) => {
        if (result.data) {
          setSurvey(result.data);
          let totalPercentage = result.data.q1["Si"].total + 
            result.data.q1["No"].total + 
            result.data.q1["podria"].total +
            result.data.q3["Arquitecto"].total +
            result.data.q3["Diseñador de interiores"].total +
            result.data.q3["Distribuidor de materiales para construcción"].total +
            result.data.q3["Distribuidor de Paneles/Madera"].total +
            result.data.q3["Paisajista /Proyectista"].total +
            result.data.q3["Desarrollador"].total +
            result.data.q3["Ingeniero"].total +
            result.data.q3["Constructor"].total +
            result.data.q3["Fabricante de Muebles"].total +
            result.data.q3["Medios"].total +
            result.data.q3["Gobierno"].total +
            result.data.q3["Asociación"].total +
            result.data.q3["Profesor"].total +
            result.data.q3["Estudiante"].total +
            result.data.q3["Otro"].total;
          setTotalPeople(totalPercentage);
        }
        setLoading(false);
      })
      .catch((error: any) => {});
  };

  const roundNumber = (num:number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  return (
    <div className="report">
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <h2>Reporte general por curso</h2>
          <Row className="align-items-end">
            <Col>
              <Form.Group controlId="course_id" className="m-0">
                <Form.Label>Curso</Form.Label>
                <Form.Control as="select" name="course_id"
                  onChange={onChangeHandlerFn}>
                  <option></option>
                  {
                    courses.map((item:any, index:number)=>(
                      <option value={item._id} key={index}>{ item.title }</option>
                    ))
                  }
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="date_from" className="m-0">
                <Form.Label>Desde:</Form.Label>
                <Form.Control
                  type="date"
                  name="date_from"
                  onChange={onChangeHandlerFn}
                  defaultValue={filters["date_from"]}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="date_to" className="m-0">
                <Form.Label>Hasta:</Form.Label>
                <Form.Control
                  type="date"
                  name="date_to"
                  onChange={onChangeHandlerFn}
                  defaultValue={filters["date_to"]}
                />
              </Form.Group>
            </Col>
            <Col>
              <Button
                variant="primary"
                className="w-100"
                onClick={searchResults}
                disabled={ !filters.course_id }
              >
                Buscar
              </Button>
            </Col>
          </Row>
          <br/>
          { loading &&
            <Row className="pt-5 pb-5 justify-content-center w-100">
              <Col xs={12} md={4}>
                <Loader
                  type="Oval"
                  color="#439a86"
                  visible={true}
                  width={300}
                  height={300}
                />
              </Col>
            </Row>
          }
          { (survey && survey.total ) ? (   
            <div>
              <div className="mt-5 report-table" ref={ref}>
                <b className="text-center"><p>{ selectedCourse.title }</p></b>

                <p><b> { survey.total }  SURVEYS</b></p>
                <Table className="question-title container">
                  <tbody>
                    <tr>        
                      <td className="w-25">
                        <p>1)</p>
                      </td>
                      <td className="w-75">
                        <p><b>Please, check the box that indicates your profession/activity.</b></p>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table>
                  <tbody>
                    <tr>
                      <td className="w-25">
                        
                      </td>
                      <td className="w-75">
                        TOTAL
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Importer / YES
                      </td>
                      <td className="w-75">
                         { survey.q1["Si"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        NO
                      </td>
                      <td className="w-75">
                         { survey.q1["No"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        I could do it
                      </td>
                      <td className="w-75">
                         { survey.q1["podria"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Countries (importations or use)
                      </td>
                      <td className="w-75">
                        { survey.q2.total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Architect
                      </td>
                      <td className="w-75">
                         { survey.q3["Arquitecto"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Interior Designer
                      </td>
                      <td className="w-75">
                         { survey.q3["Diseñador de interiores"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Building Material Distributor
                      </td>
                      <td className="w-75">
                         { survey.q3["Distribuidor de materiales para construcción"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Panels or lumber Distributor
                      </td>
                      <td className="w-75">
                         { survey.q3["Distribuidor de Paneles/Madera"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Landscape Designer/Planner
                      </td>
                      <td className="w-75">
                         { survey.q3["Paisajista /Proyectista"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Developer
                      </td>
                      <td className="w-75">
                         { survey.q3["Desarrollador"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Engineer
                      </td>
                      <td className="w-75">
                         { survey.q3["Ingeniero"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Builder
                      </td>
                      <td className="w-75">
                         { survey.q3["Constructor"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Furniture Manufacturer
                      </td>
                      <td className="w-75">
                         { survey.q3["Fabricante de Muebles"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Media
                      </td>
                      <td className="w-75">
                         { survey.q3["Medios"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Government
                      </td>
                      <td className="w-75">
                         { survey.q3["Gobierno"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Trade Association
                      </td>
                      <td className="w-75">
                         { survey.q3["Asociación"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Profesor / Teacher
                      </td>
                      <td className="w-75">
                         { survey.q3["Profesor"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Student
                      </td>
                      <td className="w-75">
                         { survey.q3["Estudiante"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Others: Buyer, wood preservation, plywood manufacturer and sawmill, stairs manufacturer
                      </td>
                      <td className="w-75">
                         { survey.q3["Otro"].total } 
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table className="question-title container">
                  <tbody>
                    <tr>        
                      <td className="w-25">
                        <p>2)</p>
                      </td>
                      <td className="w-75">
                        <p><b>Type of panel or lumber that you know or specify.</b></p>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table >
                  <tbody>
                    <tr>
                      <td className="w-25">
                        
                      </td>
                      <td className="w-75">
                        TOTAL
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Southern Yellow Pine
                      </td>
                      <td className="w-75">
                         { survey.q4["Pino Amarillo del Sur"].total }
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        SYP treated
                      </td>
                      <td className="w-75">
                         { survey.q4["Pino Amarillo tratado"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        OSB
                      </td>
                      <td className="w-75">
                         { survey.q4["OSB"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Plywood
                      </td>
                      <td className="w-75">
                         { survey.q4["Triplay /Contrachapado"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Glulam beams
                      </td>
                      <td className="w-75">
                         { survey.q4["Glulam /Vigas Laminadas"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        I Joist
                      </td>
                      <td className="w-75">
                         { survey.q4["Vigas I"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        HDO/MDO/PSF
                      </td>
                      <td className="w-75">
                         { survey.q4["HDO / MDO / PSF"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Cross-Laminated Timber (CLT)
                      </td>
                      <td className="w-75">
                         { survey.q4["Cross-Laminated Timber (CLT)"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Structural Composite Lumber (SCL)
                      </td>
                      <td className="w-75">
                         { survey.q4["Madera Estructural Compuesta (SCL) – LVL, PSL, LSL, OLS –"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        I have not worked with panels or lumber from USA
                      </td>
                      <td className="w-75">
                         { survey.q4["**No he trabajado con paneles/madera de E.U."].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Others: oak veneer, MDF, HDF, other treated lumber
                      </td>
                      <td className="w-75">
                         { survey.q4["Otro"].total } 
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table className="question-title container">
                  <tbody>                    
                    <tr>        
                      <td className="w-25">
                        <p>3)</p>
                      </td>
                      <td className="w-75">
                        <p><b>What kind of information / materials could provide you American Softwoods to encourage you to use or specify panels or lumber from USA more often?</b></p>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table >
                  <tbody>
                    <tr>
                      <td className="w-25">
                        
                      </td>
                      <td className="w-75">
                        TOTAL
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Contact information of US companies
                      </td>
                      <td className="w-75">
                         { survey.q5["Información de contacto de compañías de E.U."].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Information about varieties of panels and lumber and its uses
                      </td>
                      <td className="w-75">
                         { survey.q5["Información y usos de los diferentes paneles y maderas de coníferas de E.U."].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Information about codes / standards
                      </td>
                      <td className="w-75">
                         { survey.q5["Información sobre códigos / estándares"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Sustainability and environmental information about wood products
                      </td>
                      <td className="w-75">
                         { survey.q5["Información sobre sostenibilidad de los productos de madera de E.U."].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Technical support
                      </td>
                      <td className="w-75">
                         { survey.q5["Soporte técnico"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        General information about US market 
                      </td>
                      <td className="w-75">
                         { survey.q5["Información general sobre el mercado de E.U."].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Statistics about US Forest Products
                      </td>
                      <td className="w-75">
                         { survey.q5["Estadísticas de la industria forestal de E.U."].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Local Distributors
                      </td>
                      <td className="w-75">
                         { survey.q5["Información de distribuidores locales"].total } 
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Other: Contact information of the PSF manufacturers in USA, information about veneer for appearance, costs, technical help with the construction with wood in Mexico, information about CLT
                      </td>
                      <td className="w-75">
                         { survey.q5["Otro"].total } 
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table className="question-title container">
                  <tbody>
                    <tr>        
                      <td className="w-25">
                        <p>4)</p>
                      </td>
                      <td className="w-75">
                        <p><b>How do you qualify the next topics of the workshop?</b></p>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className="question-speaker">
                  <p><b>SPEAKERS</b></p>
                </div>
                <Table >
                  <thead>
                    <tr>                              
                      <th colSpan={2}>
                        <p><b>Exposition</b></p>
                      </th>
                    </tr>  
                  </thead>
                  <tbody>
                    <tr>
                      <td className="w-25">
                        Excellent
                      </td>
                      <td className="w-25">
                         { survey.expo["Excelente"].total }   
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Good
                      </td>
                      <td className="w-25">
                         { survey.expo["Bueno"].total }      
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Regular
                      </td>
                      <td className="w-25">
                         { survey.expo["Regular"].total }      
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Bad
                      </td>
                      <td className="w-25">
                         { survey.expo["Malo"].total }      
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table >
                  <thead>
                    <tr>                              
                      <th colSpan={2}>
                        <p><b>Presentation</b></p>
                      </th>
                    </tr>  
                  </thead>
                  <tbody>
                    <tr>
                      <td className="w-25">
                        Excellent
                      </td>
                      <td className="w-25">
                         { survey.prese["Excelente"].total }   
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Good
                      </td>
                      <td className="w-25">
                         { survey.prese["Bueno"].total }      
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Regular
                      </td>
                      <td className="w-25">
                         { survey.prese["Regular"].total }      
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Bad
                      </td>
                      <td className="w-25">
                         { survey.prese["Malo"].total }      
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table >
                  <thead>
                    <tr>                              
                      <th colSpan={2}>
                        <p><b>SEMINAR FORMAT</b></p>
                      </th>
                    </tr>  
                  </thead>
                  <tbody>
                    <tr>
                      <td className="w-25">
                        Excellent
                      </td>
                      <td className="w-25">
                         { survey.format["Excelente"].total }   
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Good
                      </td>
                      <td className="w-25">
                         { survey.format["Bueno"].total }      
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Regular
                      </td>
                      <td className="w-25">
                         { survey.format["Regular"].total }      
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25">
                        Bad
                      </td>
                      <td className="w-25">
                         { survey.format["Malo"].total }      
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <p className="text-center"><b>Totally { survey.total } APA survey frosm were received from the Seminar " { selectedCourse.title } "<br/> date </b></p>
                <Table>
                  <tbody>
                    <tr>        
                      <td className="w-50">
                        <p>Please select the box that indicates your profession:</p>
                        <Table>
                          <tbody>
                            <tr>
                              <td>Importer - Yes</td>
                              <td> { survey.q1["Si"].total } </td>
                              <td> { roundNumber( (survey.q1["Si"].total / totalPeople) * 100 ) } </td>
                            </tr>
                            <tr>
                              <td>Not importer</td>
                              <td> { survey.q1["No"].total } </td>
                              <td> { roundNumber((survey.q1["No"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>I could do it</td>
                              <td> { survey.q1["podria"].total } </td>
                              <td> { roundNumber((survey.q1["podria"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>Architect</td>
                              <td> { survey.q3["Arquitecto"].total } </td>
                              <td> { roundNumber((survey.q3["Arquitecto"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>Interior Designer</td>
                              <td> {survey.q3["Diseñador de interiores"].total} </td>
                              <td> { roundNumber((survey.q3["Diseñador de interiores"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>Building Material Distributor</td>
                              <td> { survey.q3["Distribuidor de materiales para construcción"].total } </td>
                              <td> { roundNumber((survey.q3["Distribuidor de materiales para construcción"].total / totalPeople) * 100 )} </td>
                            </tr>
                            <tr>
                              <td>Panels or lumber Distributor</td>
                              <td> { survey.q3["Distribuidor de Paneles/Madera"].total } </td>
                              <td> { roundNumber((survey.q3["Distribuidor de Paneles/Madera"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>Landscape Designer/Planner</td>
                              <td> { survey.q3["Paisajista /Proyectista"].total } </td>
                              <td> { roundNumber((survey.q3["Paisajista /Proyectista"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>Developer</td>
                              <td> { survey.q3["Desarrollador"].total } </td>
                              <td> { roundNumber((survey.q3["Desarrollador"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>Engineer</td>
                              <td> { survey.q3["Ingeniero"].total } </td>
                              <td> { roundNumber((survey.q3["Ingeniero"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>Builder</td>
                              <td> { survey.q3["Constructor"].total } </td>
                              <td> { roundNumber((survey.q3["Constructor"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>Furniture Manufacturer</td>
                              <td> { survey.q3["Fabricante de Muebles"].total } </td>
                              <td> { roundNumber((survey.q3["Fabricante de Muebles"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>Media</td>
                              <td> { survey.q3["Medios"].total } </td>
                              <td> { roundNumber((survey.q3["Medios"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>Government</td>
                              <td> { survey.q3["Gobierno"].total } </td>
                              <td> { roundNumber((survey.q3["Gobierno"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>Trade Association</td>
                              <td> { survey.q3["Asociación"].total } </td>
                              <td> { roundNumber((survey.q3["Asociación"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>Profesor / Teacher</td>
                              <td> { survey.q3["Profesor"].total } </td>
                              <td> { roundNumber((survey.q3["Profesor"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>Student</td>
                              <td> { survey.q3["Estudiante"].total } </td>
                              <td> { roundNumber((survey.q3["Estudiante"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td>Others</td>
                              <td> { survey.q3["Otro"].total } </td>
                              <td> { roundNumber((survey.q3["Otro"].total / totalPeople) * 100) } </td>
                            </tr>
                            <tr>
                              <td><b>Total</b></td>
                              <td> { totalPeople } </td>
                              <td> 100% </td>
                            </tr>
                          </tbody>
                        </Table>
                        <p>Others: Buyer, wood preservation, plywood manufacturer and sawmill, stairs manufacturer</p>
                      </td>
                      <td className="w-50">
                        <Pie width={300}
                          height={300}
                          options={{ maintainAspectRatio: false }}
                          data={{
                            "datasets": [{
                              "data": [
                                roundNumber((survey.q1["Si"].total / totalPeople) * 100), 
                                roundNumber((survey.q1["No"].total / totalPeople) * 100), 
                                roundNumber((survey.q1["podria"].total / totalPeople) * 100),
                                roundNumber((survey.q3["Arquitecto"].total / totalPeople) * 100),
                                roundNumber((survey.q3["Diseñador de interiores"].total / totalPeople) * 100),
                                roundNumber((survey.q3["Distribuidor de materiales para construcción"].total / totalPeople) * 100) ,
                                roundNumber((survey.q3["Distribuidor de Paneles/Madera"].total / totalPeople) * 100) ,
                                roundNumber((survey.q3["Paisajista /Proyectista"].total / totalPeople) * 100) ,
                                roundNumber((survey.q3["Desarrollador"].total / totalPeople) * 100) ,
                                roundNumber((survey.q3["Ingeniero"].total / totalPeople) * 100) ,
                                roundNumber((survey.q3["Constructor"].total / totalPeople) * 100) ,
                                roundNumber((survey.q3["Fabricante de Muebles"].total / totalPeople) * 100) ,
                                roundNumber((survey.q3["Medios"].total / totalPeople) * 100) ,
                                roundNumber((survey.q3["Gobierno"].total / totalPeople) * 100) ,
                                roundNumber((survey.q3["Asociación"].total / totalPeople) * 100) ,
                                roundNumber((survey.q3["Profesor"].total / totalPeople) * 100) ,
                                roundNumber((survey.q3["Estudiante"].total / totalPeople) * 100) ,
                                roundNumber((survey.q3["Otro"].total / totalPeople) * 100)
                              ],
                              "backgroundColor":[
                                "rgba(251, 125, 70, 0.4)",
                                "rgba(18, 215, 151, 0.8)",
                                "rgba(86, 7, 193, 0.7)",
                                "rgba(47, 15, 165, 0.5)",
                                "rgba(202, 210, 66, 1)",
                                "rgba(59, 177, 224, 0.5)",
                                "rgba(234, 20, 14, 0.2)",
                                "rgba(168, 193, 116, 0.9)",
                                "rgba(143, 239, 53, 0.1)",
                                "rgba(75, 20, 27, 0.2)",
                                "rgba(32, 243, 249, 0.9)",
                                "rgba(89, 142, 5, 0.5)",
                                "rgba(141, 54, 14, 0.7)",
                                "rgba(177, 214, 32, 0.4)",
                                "rgba(210, 112, 164, 0.9)",
                                "rgba(197, 187, 78, 1)",
                                "rgba(245, 143, 124, 0.7)",
                                "rgba(170, 54, 55, 0.7)",
                                "rgba(175, 242, 210, 0.6)"
                              ]
                            }],
                            "labels": [
                                'Importer - Yes',
                                'Not importer',
                                'I could do it',
                                'Architect',
                                'Interior Designer',
                                'Building Material Distributor',
                                'Panels or lumber Distributor',
                                'Landscape Designer/Planner',
                                'Developer',
                                'Engineer',
                                'Builder',
                                'Furniture Manufacturer',
                                'Media',
                                'Government',
                                'Trade Association',
                                'Profesor / Teacher',
                                'Student',
                                'Others'
                            ]
                        }}/>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <p>Please use scale "5" to "1" to rate the following questions: ("5" means "Excellent", "4" means "Very Good", "3" means "Good", "2" means "Fair" and "1" means "Poor".)</p>
                <Table className="container">
                  <tbody>
                    <tr>        
                      <td className="w-75">
                        <Table >
                          <thead>      
                            <tr>                              
                              <th className="w-50">
                                <p>RANKING</p>
                              </th>
                              <th>
                                <p>5</p>
                              </th>
                              <th>
                                <p>4</p>
                              </th>
                              <th>
                                <p>3</p>
                              </th>
                              <th>
                                <p>2</p>
                              </th>
                              <th>
                                <p>1</p>
                              </th>
                              <th>
                                <p>Sum</p>
                              </th>
                            </tr>        
                          </thead>
                          <tbody>
                            <tr>
                              <td >
                                1. How would you rate the overall content of the seminar?
                              </td>
                              <td>
                                 { survey.apa_0["Excelente"].total } 
                              </td>
                              <td>
                                 { survey.apa_0["Muy bueno"].total }  
                              </td>
                              <td>
                                 { survey.apa_0["Bueno"].total }  
                              </td>
                              <td>
                                 { survey.apa_0["Más o menos"].total }  
                              </td>
                              <td>
                                 { survey.apa_0["Nada"].total }  
                              </td>
                              <td>
                                 { survey.apa_0["Excelente"].total + 
                                   survey.apa_0["Muy bueno"].total + 
                                   survey.apa_0["Bueno"].total +
                                   survey.apa_0["Más o menos"].total +
                                   survey.apa_0["Nada"].total }  
                              </td>
                            </tr>
                          </tbody>
                        </Table>          
                      </td>
                      <td className="w-25">
                        <Pie width={300}
                          height={300}
                          options={{ maintainAspectRatio: false }}
                          data={{
                            "datasets": [{
                              "data": [
                                roundNumber( (survey.apa_0["Excelente"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_0["Muy bueno"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_0["Bueno"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_0["Más o menos"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_0["Nada"].total / survey.total) * 100 )
                              ],
                              "backgroundColor":[
                                "rgba(251, 125, 70, 0.4)",
                                "rgba(18, 215, 151, 0.8)",
                                "rgba(86, 7, 193, 0.7)",
                                "rgba(47, 15, 165, 0.5)",
                                "rgba(202, 210, 66, 1)",
                              ]
                            }],
                            "labels": [
                                '5',
                                '4',
                                '3',
                                '2',
                                '1',
                            ]
                        }}/> 
                      </td>      
                    </tr>
                    <tr>        
                      <td className="w-75">
                        <Table >
                          <tbody>
                            <tr>
                              <td className="w-50">
                                2. Do you already have adequate knowledge about U.S. structural panel types, grades and uses to specify them for the correct applications for the work that your company is involved in?
                              </td>
                              <td>
                                 { survey.apa_1["Excelente"].total } 
                              </td>
                              <td>
                                 { survey.apa_1["Muy bueno"].total }  
                              </td>
                              <td>
                                 { survey.apa_1["Bueno"].total }  
                              </td>
                              <td>
                                 { survey.apa_1["Más o menos"].total }  
                              </td>
                              <td>
                                 { survey.apa_1["Nada"].total }  
                              </td>
                              <td>
                                 { survey.apa_1["Excelente"].total + 
                                   survey.apa_1["Muy bueno"].total + 
                                   survey.apa_1["Bueno"].total +
                                   survey.apa_1["Más o menos"].total +
                                   survey.apa_1["Nada"].total }  
                              </td>
                            </tr>
                          </tbody>
                        </Table>          
                      </td>
                      <td className="w-25">
                         <Pie width={300}
                          height={300}
                          options={{ maintainAspectRatio: false }}
                          data={{
                            "datasets": [{
                              "data": [
                                roundNumber( (survey.apa_1["Excelente"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_1["Muy bueno"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_1["Bueno"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_1["Más o menos"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_1["Nada"].total / survey.total) * 100 )
                              ],
                              "backgroundColor":[
                                "rgba(251, 125, 70, 0.4)",
                                "rgba(18, 215, 151, 0.8)",
                                "rgba(86, 7, 193, 0.7)",
                                "rgba(47, 15, 165, 0.5)",
                                "rgba(202, 210, 66, 1)",
                              ]
                            }],
                            "labels": [
                              '5',
                                '4',
                                '3',
                                '2',
                                '1',
                            ]
                        }}/>  
                      </td>      
                    </tr>
                    <tr>        
                      <td className="w-75">
                        <Table >
                          <tbody>
                            <tr>
                              <td className="w-50">
                                3. Did you previously know that U.S. structural panels are available for your company’s usage?
                              </td>
                              <td>
                                 { survey.apa_2["Totalmente"].total } 
                              </td>
                              <td>
                                 { survey.apa_2["Mucho"].total }  
                              </td>
                              <td>
                                 { survey.apa_2["Regular"].total }  
                              </td>
                              <td>
                                 { survey.apa_2["Poco"].total }  
                              </td>
                              <td>
                                 { survey.apa_2["Nada"].total }  
                              </td>
                              <td>
                                 { survey.apa_2["Totalmente"].total + 
                                   survey.apa_2["Mucho"].total + 
                                   survey.apa_2["Regular"].total +
                                   survey.apa_2["Poco"].total +
                                   survey.apa_2["Nada"].total }  
                              </td>
                            </tr>
                          </tbody>
                        </Table>          
                      </td>
                      <td className="w-25">
                         <Pie width={300}
                          height={300}
                          options={{ maintainAspectRatio: false }}
                          data={{
                            "datasets": [{
                              "data": [
                                roundNumber( (survey.apa_2["Totalmente"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_2["Mucho"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_2["Regular"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_2["Poco"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_2["Nada"].total / survey.total) * 100 )
                              ],
                              "backgroundColor":[
                                "rgba(251, 125, 70, 0.4)",
                                "rgba(18, 215, 151, 0.8)",
                                "rgba(86, 7, 193, 0.7)",
                                "rgba(47, 15, 165, 0.5)",
                                "rgba(202, 210, 66, 1)",
                              ]
                            }],
                            "labels": [
                              '5',
                                '4',
                                '3',
                                '2',
                                '1',
                            ]
                        }}/>  
                      </td>      
                    </tr>  
                    <tr>        
                      <td className="w-75">
                        <Table >
                          <tbody>
                            <tr>
                              <td className="w-50">
                                4. Did you previously know of the value that U.S. structural panels can provide to enhance your company’s business?
                              </td>
                              <td>
                                 { survey.apa_3["Totalmente"].total } 
                              </td>
                              <td>
                                 { survey.apa_3["Mucho"].total }  
                              </td>
                              <td>
                                 { survey.apa_3["Regular"].total }  
                              </td>
                              <td>
                                 { survey.apa_3["Poco"].total }  
                              </td>
                              <td>
                                 { survey.apa_3["Nada"].total }  
                              </td>
                              <td>
                                 { survey.apa_3["Totalmente"].total + 
                                   survey.apa_3["Mucho"].total + 
                                   survey.apa_3["Regular"].total +
                                   survey.apa_3["Poco"].total +
                                   survey.apa_3["Nada"].total }  
                              </td>
                            </tr>
                          </tbody>
                        </Table>          
                      </td>
                      <td className="w-25">
                         <Pie width={300}
                          height={300}
                          options={{ maintainAspectRatio: false }}
                          data={{
                            "datasets": [{
                              "data": [
                                roundNumber( (survey.apa_3["Totalmente"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_3["Mucho"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_3["Regular"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_3["Poco"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_3["Nada"].total / survey.total) * 100 )
                              ],
                              "backgroundColor":[
                                "rgba(251, 125, 70, 0.4)",
                                "rgba(18, 215, 151, 0.8)",
                                "rgba(86, 7, 193, 0.7)",
                                "rgba(47, 15, 165, 0.5)",
                                "rgba(202, 210, 66, 1)",
                              ]
                            }],
                            "labels": [
                              '5',
                                '4',
                                '3',
                                '2',
                                '1',
                            ]
                        }}/>  
                      </td>      
                    </tr>
                    <tr>        
                      <td className="w-75">
                        <Table >
                          <tbody>
                            <tr>
                              <td className="w-50">
                                5. Did you previously know of the importance of “certified” panels product, such as that produced in the United States, and the role this plays to ensure proper structural integrity and long-term usability of buildings?
                              </td>
                              <td>
                                 { survey.apa_4["Totalmente"].total } 
                              </td>
                              <td>
                                 { survey.apa_4["Mucho"].total }  
                              </td>
                              <td>
                                 { survey.apa_4["Regular"].total }  
                              </td>
                              <td>
                                 { survey.apa_4["Poco"].total }  
                              </td>
                              <td>
                                 { survey.apa_4["Nada"].total }  
                              </td>
                              <td>
                                 { survey.apa_4["Totalmente"].total + 
                                   survey.apa_4["Mucho"].total + 
                                   survey.apa_4["Regular"].total +
                                   survey.apa_4["Poco"].total +
                                   survey.apa_4["Nada"].total }  
                              </td>
                            </tr>
                          </tbody>
                        </Table>          
                      </td>
                      <td className="w-25">
                         <Pie width={300}
                          height={300}
                          options={{ maintainAspectRatio: false }}
                          data={{
                            "datasets": [{
                              "data": [
                                roundNumber( (survey.apa_4["Totalmente"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_4["Mucho"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_4["Regular"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_4["Poco"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_4["Nada"].total / survey.total) * 100 )
                              ],
                              "backgroundColor":[
                                "rgba(251, 125, 70, 0.4)",
                                "rgba(18, 215, 151, 0.8)",
                                "rgba(86, 7, 193, 0.7)",
                                "rgba(47, 15, 165, 0.5)",
                                "rgba(202, 210, 66, 1)",
                              ]
                            }],
                            "labels": [
                              '5',
                                '4',
                                '3',
                                '2',
                                '1',
                            ]
                        }}/>  
                      </td>      
                    </tr>
                    <tr>        
                      <td className="w-75">
                        <Table >
                          <tbody>
                            <tr>
                              <td className="w-50">
                                6. Did you previously know that U.S. structural panels are good value for the money and can be cost effective and can be a better alternative for many reasons compared to steel in construction systems?
                              </td>
                              <td>
                                 { survey.apa_5["Totalmente"].total } 
                              </td>
                              <td>
                                 { survey.apa_5["Mucho"].total }  
                              </td>
                              <td>
                                 { survey.apa_5["Regular"].total }  
                              </td>
                              <td>
                                 { survey.apa_5["Poco"].total }  
                              </td>
                              <td>
                                 { survey.apa_5["Nada"].total }  
                              </td>
                              <td>
                                 { survey.apa_5["Totalmente"].total + 
                                   survey.apa_5["Mucho"].total + 
                                   survey.apa_5["Regular"].total +
                                   survey.apa_5["Poco"].total +
                                   survey.apa_5["Nada"].total }  
                              </td>
                            </tr>
                          </tbody>
                        </Table>          
                      </td>
                      <td className="w-25">
                         <Pie width={300}
                          height={300}
                          options={{ maintainAspectRatio: false }}
                          data={{
                            "datasets": [{
                              "data": [
                                roundNumber( (survey.apa_5["Totalmente"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_5["Mucho"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_5["Regular"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_5["Poco"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_5["Nada"].total / survey.total) * 100 )
                              ],
                              "backgroundColor":[
                                "rgba(251, 125, 70, 0.4)",
                                "rgba(18, 215, 151, 0.8)",
                                "rgba(86, 7, 193, 0.7)",
                                "rgba(47, 15, 165, 0.5)",
                                "rgba(202, 210, 66, 1)",
                              ]
                            }],
                            "labels": [
                              '5',
                                '4',
                                '3',
                                '2',
                                '1',
                            ]
                        }}/>  
                      </td>      
                    </tr>
                    <tr>        
                      <td className="w-75">
                        <Table >
                          <tbody>
                            <tr>
                              <td className="w-50">
                                7. Did you previously know that U.S. structural panels are recognized internationally in building codes for the crucial role it can play in building safety and demanding construction applications and that panels can be used for construction?
                              </td>
                              <td>
                                 { survey.apa_6["Totalmente"].total } 
                              </td>
                              <td>
                                 { survey.apa_6["Mucho"].total }  
                              </td>
                              <td>
                                 { survey.apa_6["Regular"].total }  
                              </td>
                              <td>
                                 { survey.apa_6["Poco"].total }  
                              </td>
                              <td>
                                 { survey.apa_6["Nada"].total }  
                              </td>
                              <td>
                                 { survey.apa_6["Totalmente"].total + 
                                   survey.apa_6["Mucho"].total + 
                                   survey.apa_6["Regular"].total +
                                   survey.apa_6["Poco"].total +
                                   survey.apa_6["Nada"].total }  
                              </td>
                            </tr>
                          </tbody>
                        </Table>          
                      </td>
                      <td className="w-25">
                         <Pie width={300}
                          height={300}
                          options={{ maintainAspectRatio: false }}
                          data={{
                            "datasets": [{
                              "data": [
                                roundNumber( (survey.apa_6["Totalmente"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_6["Mucho"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_6["Regular"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_6["Poco"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_6["Nada"].total / survey.total) * 100 )
                              ],
                              "backgroundColor":[
                                "rgba(251, 125, 70, 0.4)",
                                "rgba(18, 215, 151, 0.8)",
                                "rgba(86, 7, 193, 0.7)",
                                "rgba(47, 15, 165, 0.5)",
                                "rgba(202, 210, 66, 1)",
                              ]
                            }],
                            "labels": [
                              '5',
                                '4',
                                '3',
                                '2',
                                '1',
                            ]
                        }}/>  
                      </td>      
                    </tr>
                    <tr>        
                      <td className="w-75">
                        <Table >
                          <tbody>
                            <tr>
                              <td className="w-50">
                                8. Did you previously know that U.S. structural panels are made from sustainable temperate forests, reduces carbon compared to other building materials and is environmentally-friendly?
                              </td>
                              <td>
                                 { survey.apa_7["Totalmente"].total } 
                              </td>
                              <td>
                                 { survey.apa_7["Mucho"].total }  
                              </td>
                              <td>
                                 { survey.apa_7["Regular"].total }  
                              </td>
                              <td>
                                 { survey.apa_7["Poco"].total }  
                              </td>
                              <td>
                                 { survey.apa_7["Nada"].total }  
                              </td>
                              <td>
                                 { survey.apa_7["Totalmente"].total + 
                                   survey.apa_7["Mucho"].total + 
                                   survey.apa_7["Regular"].total +
                                   survey.apa_7["Poco"].total +
                                   survey.apa_7["Nada"].total }  
                              </td>
                            </tr>
                          </tbody>
                        </Table>          
                      </td>
                      <td className="w-25">
                         <Pie width={300}
                          height={300}
                          options={{ maintainAspectRatio: false }}
                          data={{
                            "datasets": [{
                              "data": [
                                roundNumber( (survey.apa_7["Totalmente"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_7["Mucho"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_7["Regular"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_7["Poco"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_7["Nada"].total / survey.total) * 100 )
                              ],
                              "backgroundColor":[
                                "rgba(251, 125, 70, 0.4)",
                                "rgba(18, 215, 151, 0.8)",
                                "rgba(86, 7, 193, 0.7)",
                                "rgba(47, 15, 165, 0.5)",
                                "rgba(202, 210, 66, 1)",
                              ]
                            }],
                            "labels": [
                              '5',
                                '4',
                                '3',
                                '2',
                                '1',
                            ]
                        }}/>  
                      </td>      
                    </tr>
                    <tr>        
                      <td className="w-75">
                        <Table >
                          <tbody>
                            <tr>
                              <td className="w-50">
                                9. Has your knowledge and understanding of U.S. structural panels and its uses increased after contact with AMSO/APA?
                              </td>
                              <td>
                                 { survey.apa_8["Totalmente"].total } 
                              </td>
                              <td>
                                 { survey.apa_8["Mucho"].total }  
                              </td>
                              <td>
                                 { survey.apa_8["Regular"].total }  
                              </td>
                              <td>
                                 { survey.apa_8["Poco"].total }  
                              </td>
                              <td>
                                 { survey.apa_8["Nada"].total }  
                              </td>
                              <td>
                                 { survey.apa_8["Totalmente"].total + 
                                   survey.apa_8["Mucho"].total + 
                                   survey.apa_8["Regular"].total +
                                   survey.apa_8["Poco"].total +
                                   survey.apa_8["Nada"].total }  
                              </td>
                            </tr>
                          </tbody>
                        </Table>          
                      </td>
                      <td className="w-25">
                         <Pie width={300}
                          height={300}
                          options={{ maintainAspectRatio: false }}
                          data={{
                            "datasets": [{
                              "data": [
                                roundNumber( (survey.apa_8["Totalmente"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_8["Mucho"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_8["Regular"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_8["Poco"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_8["Nada"].total / survey.total) * 100 )
                              ],
                              "backgroundColor":[
                                "rgba(251, 125, 70, 0.4)",
                                "rgba(18, 215, 151, 0.8)",
                                "rgba(86, 7, 193, 0.7)",
                                "rgba(47, 15, 165, 0.5)",
                                "rgba(202, 210, 66, 1)",
                              ]
                            }],
                            "labels": [
                              '5',
                                '4',
                                '3',
                                '2',
                                '1',
                            ]
                        }}/>  
                      </td>      
                    </tr>
                    <tr>        
                      <td className="w-75">
                        <Table >
                          <tbody>
                            <tr>
                              <td className="w-50">
                                10. How likely is your company to specify more U.S. structural panels after attending this seminar?
                              </td>
                              <td>
                                 { survey.apa_9["Totalmente"].total } 
                              </td>
                              <td>
                                 { survey.apa_9["Mucho"].total }  
                              </td>
                              <td>
                                 { survey.apa_9["Regular"].total }  
                              </td>
                              <td>
                                 { survey.apa_9["Poco"].total }  
                              </td>
                              <td>
                                 { survey.apa_9["Nada"].total }  
                              </td>
                              <td>
                                 { survey.apa_9["Totalmente"].total + 
                                   survey.apa_9["Mucho"].total + 
                                   survey.apa_9["Regular"].total +
                                   survey.apa_9["Poco"].total +
                                   survey.apa_9["Nada"].total }  
                              </td>
                            </tr>
                          </tbody>
                        </Table>          
                      </td>
                      <td className="w-25">
                         <Pie width={300}
                          height={300}
                          options={{ maintainAspectRatio: false }}
                          data={{
                            "datasets": [{
                              "data": [
                                roundNumber( (survey.apa_9["Totalmente"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_9["Mucho"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_9["Regular"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_9["Poco"].total / survey.total) * 100 ),
                                roundNumber( (survey.apa_9["Nada"].total / survey.total) * 100 )
                              ],
                              "backgroundColor":[
                                "rgba(251, 125, 70, 0.4)",
                                "rgba(18, 215, 151, 0.8)",
                                "rgba(86, 7, 193, 0.7)",
                                "rgba(47, 15, 165, 0.5)",
                                "rgba(202, 210, 66, 1)",
                              ]
                            }],
                            "labels": [
                              '5',
                                '4',
                                '3',
                                '2',
                                '1',
                            ]
                        }}/>  
                      </td>      
                    </tr>
                  </tbody>
                </Table>
                <Table >
                  <tbody>
                    <tr>
                      <td>
                        <b>11. We appreciate any other comments about the seminar.</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p> </p>
                      </td>
                    </tr>
                    {
                      survey.comments.map((item:any,index:number)=>(
                        <tr key={index}>
                          <td>
                            <p>{item}</p>
                          </td>
                        </tr>
                      ))
                    }                  
                  </tbody>
                </Table>
              </div>
            </div>
          ) : (
            <Alert variant={'warning'}>No hay resultados.</Alert>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default ReportGeneral;
