import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ setRole, ...props }: any) => {
  //console.log(flag);
  if (!localStorage.token) {
    return <Redirect to="/login" />;
  } else if (!setRole) {
    if (localStorage.token) {
      return <Route {...props} />;
    } else {
      return <Redirect to="/login" />;
    }
  } else {
    const user = JSON.parse(localStorage.user);
    if (setRole.length > 1) {
      if (localStorage.token && user && setRole.includes(user.role)) {
        return <Route {...props} />;
      } else {
        return <Redirect to="/login" />;
      }
    } else {
      if (localStorage.token && user && user.role === setRole) {
        return <Route {...props} />;
      } else {
        return <Redirect to="/login" />;
      }
    }
  }
};

export default ProtectedRoute;
