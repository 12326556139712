import React, { useState } from "react";
import { Row, Col, Card, Form, Button, Toast, Modal } from "react-bootstrap";
import "../../styles/login.scss";
import { request } from "../../services/Request";
import Loader from "react-loader-spinner";

function Login() {
  const [userData, setData]: any = useState({});
  const [userEmail, setEmail]: any = useState("");
  const [error, setErrorData] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [showModal, setModal] = useState(false);
  const [showRecoverModal, setRecoverModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChangeHandlerFn = (event: any) => {
    // update the state;
    let currentLoginData = userData;
    currentLoginData[event.target.name] = event.target.value;
    setData(currentLoginData);
  };

  const emailHandle = (event: any) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event: any) => {
    setLoading(true);
    event.preventDefault();
    userData["front"] = "staff";
    const data = JSON.stringify(userData);
    const result = request("/api/auth/signin", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: data,
    });
    //console.log(result);
    result.then((data: any) => {
      //console.log(data);
      if (data.error) {
        //console.log(data.error);
        if(data.error.status == 404){
          console.log(data.error);
          setErrorStatus(true);
          setLoading(false);
        }else{
          setLoading(false);
          setErrorData(true);
        }
      } else {
        localStorage.token = data.data.token;
        localStorage.user = JSON.stringify(data.data);
        window.location.href = "/dashboard/all-courses";
      }
    });
  };

  const handleClose = () => setModal(false);
  const handleRecoverClose = () => setRecoverModal(false);

  const recoverPassword = (e: any) => {
    e.preventDefault();
    setModal(true);
  };

  const confirmRecover = () => {
    const data = JSON.stringify({ email: userEmail });
    request("/api/auth/retrievePassword", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: data,
    }).then((result: any) => {
      console.log(result);
      if (result.error) {
        setErrorForm(true);
      } else {
        setModal(false);
        setRecoverModal(true);
      }
    });
  };

  return (
    <div className="login p-5 align-items-center justify-content-center d-flex">
      {loading ? (
        <Row className="pt-5 pb-5 justify-content-center w-100">
          <Col xs={12} md={4}>
            <Loader
              type="Oval"
              color="#439a86"
              visible={true}
              width={300}
              height={300}
            />
          </Col>
        </Row>
      ) : (
        <Row className="w-100">
          <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
            <Card className="shadow">
              <Card.Body className="p-0">
                <Card.Title className="p-3">
                  <div className="d-flex align-items-center justify-content-center">
                    <h1>Videoteca</h1>
                  </div>
                </Card.Title>
                <div className="pl-5 pr-5 pt-2 pb-2">
                  <h4 className="text-center mb-3">Ingresar</h4>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="email">
                      <Form.Control
                        type="email"
                        placeholder="Correo"
                        name="email"
                        onChange={onChangeHandlerFn}
                      />
                    </Form.Group>
                    <Form.Group controlId="password">
                      <Form.Control
                        type="password"
                        placeholder="Contraseña"
                        name="password"
                        onChange={onChangeHandlerFn}
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">
                      Ingresar
                    </Button>
                  </Form>
                  <br />
                  <Card.Text className="text-center">
                    <Button variant="link" onClick={recoverPassword} className="text-white">
                      ¿No recuerda la contraseña?
                    </Button>
                  </Card.Text>
                  <Card.Text className="text-left">
                    <a href="https://americansoftwoodslatam.com/" className="text-red">
                      {'<'} Regresar a la página principal
                    </a>
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Toast
        style={{ position: "absolute", bottom: 0, right: 0 }}
        onClose={() => setErrorData(false)}
        show={error}
      >
        <Toast.Header>
          <strong className="mr-auto">Error</strong>
        </Toast.Header>
        <Toast.Body>El usuario y la contraseña no coinciden.</Toast.Body>
      </Toast>
      <Toast
        style={{ position: "absolute", bottom: 0, right: 0 }}
        onClose={() => setErrorForm(false)}
        show={errorForm}
      >
        <Toast.Header>
          <strong className="mr-auto">Error</strong>
        </Toast.Header>
        <Toast.Body>
          Ocurrió un error al realizar el proceso. Favor de intentarlo más
          tarde.
        </Toast.Body>
      </Toast>
      <Toast
        className={ errorStatus ? 'd-block' : 'd-none' }
        onClose={() => setErrorStatus(false)}
        show={errorStatus}
      >
        <Toast.Header>
          <strong className="mr-auto">Error</strong>
        </Toast.Header>
        <Toast.Body>
          Su usuario no ha sido confirmado todavía. En su cuenta de correos debe aparecer un 
          correo con asunto <b>"Confirmación de usuario"</b> y debe presionar el enlace adjunto para validar su cuenta.
        </Toast.Body>
      </Toast>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Recuperar contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="email">
            <Form.Label>
              Ingrese su correo electrónico y enviaremos un correo con la
              solicitud para el cambio de contraseña.
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              name="email"
              onChange={emailHandle}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={confirmRecover}>
            Sí
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showRecoverModal} onHide={handleRecoverClose}>
        <Modal.Header closeButton>
          <Modal.Title>Recuperar contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Se le ha enviado un correo a su cuenta para confirmar el siguiente
          paso del proceso.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRecoverClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Login;
