import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { request, checkPermissions } from "../../../services/Request";
import { useParams } from "react-router";

function ManageNewUser() {
  const selectedFile = useRef("");
  const [user, setUser]: any = useState({});

  useEffect(()=>{
    checkPermissions("users")
    .then(()=>{

    });
  },[]);

  const onSubmitHandler = (event: any) => {
    event.preventDefault();
    request("/api/user", {
      method: "POST",
      body: JSON.stringify(user),
      headers: { "Content-Type": "application/json" }
    })
      .then((result: any) => {
        if(result.error){

        }else{
          window.location.replace("/manage/users");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const onChangeHandler = (event: any) => {
    console.log(event.target.name, event.target.value);
      let data = user;
      if(event.target.name == "permissions"){
        if(data.permissions && data.permissions.indexOf(event.target.value) > -1){
          data.permissions.splice(data.permissions.indexOf(event.target.value),1);
        }else{
          if(!data.permissions){
            data.permissions = [];
          }
          data.permissions.push(event.target.value);
        }
      }else{
        data[event.target.name] = event.target.value;
      }
      console.log(data);
      setUser(data);
  };

  return (
    <div className="manage new-user">
      <Container>
      <h1>Nuevo usuario</h1>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm={2}>
              Nombre
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="name"
                placeholder="Nombre(s)"
                onChange={onChangeHandler}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="lastname">
            <Form.Label column sm={2}>
              Apellido
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="lastname"
                placeholder="Apellido(s)"
                onChange={onChangeHandler}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="email">
            <Form.Label column sm={2}>
              Correo
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="email"
                name="email"
                placeholder="Correo electrónico"
                onChange={onChangeHandler}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="company">
            <Form.Label column sm={2}>
              Compañía
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="company"
                placeholder="Compañía"
                onChange={onChangeHandler}
              />
            </Col>
          </Form.Group>
          <br/>
          <Row>
            <Col sm={2}>
              <b>Permisos</b>
            </Col>
            <Col sm={10}>
              <Form.Check 
                type={"checkbox"}
                id={`permissions_users`}
                name="permissions"
                label={`Administrar usuarios`}
                value="users"
                onChange={onChangeHandler}
              />
              <Form.Check 
                type={"checkbox"}
                id={`permissions_courses`}
                name="permissions"
                label={`Administrar cursos`}
                value="courses"
                onChange={onChangeHandler}
              />
              <Form.Check 
                type={"checkbox"}
                id={`permissions_categories`}
                name="permissions"
                label={`Administrar categorías`}
                value="categories"
                onChange={onChangeHandler}
              />
              <Form.Check 
                type={"checkbox"}
                id={`permissions_reports`}
                name="permissions"
                label={`Administrar reportes`}
                value="reports"
                onChange={onChangeHandler}
              />
              <Form.Check 
                type={"checkbox"}
                id={`permissions_authors`}
                name="permissions"
                label={`Administrar autores`}
                value="authors"
                onChange={onChangeHandler}
              />
            </Col>
          </Row>
          <br/>
          <Form.Group as={Row}>
            <Col sm={{ span: 10, offset: 2 }}>
              <Button type="submit">Guardar</Button>
            </Col>
          </Form.Group>
        </Form>
      </Container>
    </div>
  );
}

export default ManageNewUser;
